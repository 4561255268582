import { IonCol, IonContent, IonRow, IonText, IonTitle, useIonViewDidEnter } from '@ionic/react';
import Header from '../components/Header/Header';
import { onScroll } from '../services/sticky-menu.service';
import Footer from '../components/Footer';

const TermsAndConditions: React.FC = () => {
    useIonViewDidEnter(() => {
        const tabBar = document.querySelector('ion-tab-bar');
        if (tabBar !== null) {
            tabBar.style.display = 'none';
        }
    })
    return (
        <IonContent fullscreen scrollEvents={true} onIonScroll={onScroll}>
            <Header />
            <IonRow>
                <IonCol sizeXl='10' offsetXl='1' sizeLg='10' offsetLg='1' className='no-padding bg-content'>
                    <div style={{ padding: 20, minHeight: '80vh' }}>

                        <IonTitle><h1 className='text-center'>Terms & Conditions</h1></IonTitle>
                        <IonText><hr />
                            আমাদের থেকে পন্য ক্রয়ের আগে নিম্নোক্ত বিষয়গুলো বুঝে পন্য ক্রয় করবেন যাতে বিক্রয় পরবর্তী কোন ঝামেলা না আসে। আমরা চাই আমাদের কাস্টমার আমাদের সার্ভিস সম্পর্কে ১০০% জেনে সিদ্ধান্ত গ্রহন করুন। আমাদের কোন বিষয়ে যদি কোন রকম দ্বিধা থাকে তাহলে আমাদেরকে কল করুন 01709339369  অথবা পেজ ইনবক্সে জেনে নিন।

                            <hr />
                            ✅ আমরা কোন পন্যের রেডিষ্টক রাখিনা। শুধুমাত্র আপনার অর্ডারকৃত পন্যই  চায়না সাপ্লাইয়ার থেকে ক্রয় করা হয়।

                            <hr />
                            ✅ 75 % অগ্রিম পেমেন্ট ছাড়া কোন অর্ডার নেয়া হয়না। বাকি 25% ও শিপিং চার্জ ডেলিভেরীর সময় প্রদান করতে হবে।

                            <hr />
                            ✅ আমাদের সাইটে উল্লেখিত পণ্যের ওজন সেলার এর দেয়া তথ্যের উপর ভিত্তি করে প্রদর্শিত হচ্ছে যা ১০০% সঠিক নয়। তাই এখানে উল্লেখিত ওজন দেখে বিভ্রান্ত হবেন না। পণ্য আসার পর প্রকৃত ওজনের উপর নিচে উল্লেখিত রেট অনুযায়ী শিপিং ও কাস্টমস  চার্জ আপনাকে প্রদান করতে হবে। মনে রাখবেন শিপিং ও কাস্টমস  চার্জ পণ্য মুল্যের সাথে দেখানো হয়না। পণ্য আসার পরই শুধুমাত্র শিপিং ও কাস্টমস  চার্জ হিসাব করা হয়ে থাকে। কেজি প্রতি রেট ওজনের নিচের ঘরে ও  ভিউকার্ট পেজে উল্লেখ থাকবে। অর্ডার করার সময় যে রেট উল্লেখ থাকবে সেটাই ডেলিভেরী নেওয়ার সময় ধরা হবে।

                            <hr />
                            ✅ বানিজ্যিক পন্য বাই এয়ারে আসতে আমাদের ওয়্যার হাউস পৌছনোর দিন থেকে 19 থেকে ২9 দিন এবং বাই শিপে ওয়্যার হাউস পৌছনোর দিন থেকে 48 থেকে ৬০ দিন লাগে।

                            <hr />
                            ✅ পন্য মুল্যের মধ্যে লোকাল কুরিয়ার/ ট্রান্সপোর্ট চার্জ অন্তর্ভুক্ত নয়।

                            <hr />
                            ✅ পন্য কেনার আগে অবশ্যই রিটার্ন এন্ড রিফান্ড পলিসি জেনে নিন। বাই এয়ারের পণ্য যদি 45 দিন অতিবাহিত হয়ে যায় এবং বাই শিপের পণ্য 9০ দিন অতিবাহিত হয়ে যায় তাহলে শর্ত সাপেক্ষে রিফান্ড করা হবে। এই 45 দিন/ 90  দিনের আগে রিফান্ড এপ্লাই গ্রহণ করা হবেনা।

                            <hr />
                            ✅ কাষ্টমস কর্তৃক আমদানী নিষিদ্ধ পন্য অর্ডার করা যাবেনা। যদি কেউ সরাসরি অর্ডার করেন তাহলে অর্ডার ক্যান্সেল হবে এবং আপনার পেমেন্টকৃত টাকা থেকে গেটওয়ে চার্জ 2.50 % কেটে বাকি টাকা রিফান্ড করা হবে।

                            <hr />
                            ✅ অর্ডারকৃত পন্য একাধিক সাপ্লাইয়ার ও আইটেম হলে সব আইটেম এক সাথে নাও আসতে পারে। সেক্ষেত্রে আমাদের দেওয়া সময়সীমার আগে কেউ আংশিক ডেলিভেরি নিতে চান তাহলে আপনাকে উক্ত অর্ডারের পুরো বকেয়া পরিশোধ করতে হবে। বাকি পন্য আসার পর শুধু কুরিয়ার /কাষ্টমস/ ট্রান্সপোর্ট চার্জ পেমেন্ট করে পন্য নিতে পারবেন।</IonText>
                    </div>
                </IonCol>
            </IonRow>
            <Footer />
        </IonContent>
    );
};

export default TermsAndConditions;
