import { IonButton, IonCol, IonContent, IonIcon, IonRow, IonSpinner, useIonToast } from '@ionic/react';
import Header from '../../components/Header/Header';
import { onScroll } from '../../services/sticky-menu.service';
import Footer from '../../components/Footer';
import { appSubDir } from '../../configs/app';
import { useParams } from 'react-router';
import { useEffect, useState } from 'react';
import { print } from 'ionicons/icons';
import onPrint from '../../services/print.service';
import { fetchInvoice, getInvoice } from '../../services/account/invoice.service';
import InvoiceBody from '../../components/InvoiceBody';
import InvoiceEditModal from '../../components/Admin/InvoiceEditModal';
import { InvoiceContextProvider, useInvoiceContext, useInvoiceContextUpdater } from '../../services/invoice-context.service';

const Body: React.FC = () => {
    const { invoiceID } = useParams() as any
    const [isLoading, setIsLoading] = useState(true)
    const invoice = useInvoiceContext()
    const setInvoice = useInvoiceContextUpdater()
    const [presentToast] = useIonToast();
    useEffect(() => {
        setIsLoading(true)
        fetchInvoice(invoiceID).then(setInvoice).catch(errPromise => {
            errPromise.then((errResponse: any) => {
                const messages = [];
                if (errResponse.message && Object.values(errResponse.message).length > 0) {
                    messages.push(Object.values(errResponse.message).map((item) => {
                        const temp = item as string[]
                        return temp.join(",")
                    }).join(","))
                } else if (errResponse.message) {
                    messages.push(errResponse.message)
                } else {
                    messages.push("Something Went Wrong")
                }
                presentToast({
                    message: messages.join(","),
                    duration: 3000,
                    cssClass: 'custom-toast',
                    animated: true,
                    color: "danger",
                    buttons: [
                        {
                            text: 'Dismiss',
                            role: 'cancel'
                        }
                    ],
                })
            })
        }).finally(() => {
            setIsLoading(false);
        })
    }, [invoiceID])
    return (
        <>
            <div style={{ textAlign: 'center' }} className='print-hidden'>
                {isLoading ?
                    <IonSpinner style={{ marginTop: 100, marginBottom: 100 }} /> :
                    <>
                        {invoice ? <>
                            <h1 style={{ marginTop: 20, marginBottom: 20 }}>
                                <IonButton routerLink={appSubDir + "invoices"} fill='outline'>
                                    Invoices
                                </IonButton>
                                <IonButton onClick={onPrint}>
                                    <IonIcon icon={print} slot='start' />
                                    Print
                                </IonButton>
                                <InvoiceEditModal />
                            </h1>
                        </> : <>
                            <h1 style={{ marginTop: 100, marginBottom: 100 }}>
                                Invoice Not Found
                            </h1>
                        </>}
                    </>}
            </div>
            {!isLoading && invoice && <div className='print-section'>
                <InvoiceBody />
            </div>}
        </>
    );
};
const InvoiceView: React.FC = () => {
    const invoice = useInvoiceContext()
    return (
        <IonContent scrollEvents={true} onIonScroll={onScroll}>
            <Header />
            <IonRow>
                <IonCol sizeXl='10' offsetXl='1' sizeLg='10' offsetLg='1' className='no-padding bg-content'>
                    <InvoiceContextProvider>
                        <Body />
                    </InvoiceContextProvider>
                </IonCol>
            </IonRow>
            <Footer />
        </IonContent>
    );
};

export default InvoiceView;
