/**
 * File Name    : Service - Login Service
 * Desctipn     : This file will provide necessary service to perform all the login action and functions.
 * Author       : MD. Mashfiqur Rahman
 * Website      : https://mashfiqnahid.com
 * Email        : mashfiqnahid@gmail.com
 */

import axios, { AxiosResponse } from 'axios'
import User from "../types/user.type"
import { apiBaseUrl, apiConfig } from '../configs/app'
import CryptoService from './crypto.services'
import { SignUpFormData } from '../types/auth-data.type'

const loginDetailsKey = CryptoService.hash("login-details-key")
export class LoginServiceClass {
    debugMode = false
    currentUser = this.getFromLocalStorage()
    private subscribers = [] as CallableFunction[]
    subscribe(subscriber: CallableFunction, index: number, screenName = "Unknown") {
        if (index > -1 && this.subscribers[index]) {
            subscriber()
            return index
        } else {
            index = this.subscribers.push(subscriber)
            this.debugMode && console.log(`${screenName} added new Subscriber ${index}`)
            subscriber()
            return index - 1
        }
    }
    unSubscribe(index: number) {
        if (index > -1 && this.subscribers[index]) {
            this.subscribers[index] = () => {
                this.debugMode && console.log(`Subscriber ${index + 1} Unsubscribed`)
            }
        }
    }
    private broadCast() {
        this.debugMode && console.log("CurrentUser Data Broadcasting ...")
        this.subscribers.map((subscriber: CallableFunction, index) => {
            this.debugMode && console.log(`Subscriber ${index + 1} Served`)
            subscriber()
        })
        this.debugMode && console.log("CurrentUser Data Broadcasting Completed")
    }
    load() {
        this.debugMode && console.log("Loading CurrentUser Data....")
        this.loadFromLocalStorage()
        return this.privateApiCall(apiConfig.userDetails)
            .then((response: AxiosResponse) => (this.refresh(response))).catch((err: any) => {
                console.log("load status", err)
                // window.localStorage.getItem(loginDetailsKey)
                this.debugMode && console.log("Error On load User Data")
                this.debugMode && console.log("err", JSON.stringify(err, null, 2));
                this.debugMode && err.response && console.log("err.response", JSON.stringify(err.response, null, 2));
                throw err
            })
    }
    editDetails(dataToPut: any) {
        this.debugMode && console.log("Editing CurrentUser Data....")
        return this.privatePutApiCall(apiConfig.editUserDetails, dataToPut)
            .then((response) => (this.refresh(response))).catch((err) => {
                console.log("Editing User Data status", err)
                // window.localStorage.getItem(loginDetailsKey)
                this.debugMode && console.log("Error On Editing User Data")
                this.debugMode && console.log("err", JSON.stringify(err, null, 2));
                this.debugMode && err.response && console.log("err.response", JSON.stringify(err.response, null, 2));
                throw err
            })
    }
    refresh(response: AxiosResponse) {
        if (response.data && response.data.currentUser) {
            const currentUser = response.data.currentUser as User
            this.currentUser = {
                ...this.currentUser, ...currentUser
            }
            this.debugMode && console.log("Current User Data Loaded")
            // this.debugMode && console.log(JSON.stringify(this.currentUser, null, 2))
            this.broadCast()
            this.debugMode && console.log("Loading CurrentUser Data Completed")
            return this.saveToLocalStorage()
        } else {
            throw { message: "The Api structure is unexpected" }
        }
    }


    doLogOut() {
        return this.privateGetApiCall(apiConfig.logOut).then((response) => {
            window.localStorage.removeItem(loginDetailsKey)
            this.currentUser = {} as User
            this.debugMode && console.log("Current User Data Removed from service & Local storage")
            this.broadCast()
            return response
        })
    }
    doForceFullyLogOut() {
        window.localStorage.removeItem(loginDetailsKey)
        this.currentUser = {} as User
        this.debugMode && console.log("Current User Data Removed from service & Local storage")
        this.broadCast()
    }
    doLogin(phone: string, password: string) {
        const formData = new FormData()
        formData.append('phone', phone)
        formData.append('password', password)
        return axios.post(apiConfig.loginUrl, formData).then((response) => {
            this.refresh(response)
        }).catch((err) => {
            this.debugMode && console.log("Error On Login")
            this.debugMode && console.log(JSON.stringify(err, null, 2));
            throw err
        })
    }
    doLoginWithFB(access_token: string) {
        const role_id = 2
        return axios.post(apiConfig.loginWithFBUrl, { access_token, role_id }).then((response) => (this.refresh(response))).catch((err) => {
            this.debugMode && console.log("Error On Login With Facebook")
            this.debugMode && console.log(JSON.stringify(err, null, 2));
            throw err
        })
    }
    isLoggedIn() {
        return (this.currentUser && this.currentUser.id) ? true : false
    }
    isAdmin() {
        return (this.currentUser && this.currentUser.roles.includes('admin'))
    }

    getFromLocalStorage() {
        const loginDetails = window.localStorage.getItem(loginDetailsKey)
        if (loginDetails) {
            const user = JSON.parse(CryptoService.decrypt(loginDetails))
            if (user && user.token) {
                this.debugMode && console.log("Current User Data Loaded From Local Storage")
                this.debugMode && console.log(JSON.stringify(this.currentUser, null, 2))
                return user as User
            }
        }
        return {} as User
    }
    loadFromLocalStorage() {
        const loginDetails = window.localStorage.getItem(loginDetailsKey)
        if (loginDetails) {
            const user = JSON.parse(CryptoService.decrypt(loginDetails))
            if (user && user.token) {
                this.currentUser = user
                this.debugMode && console.log("Current User Data Loaded From Local Storage")
                this.debugMode && console.log(JSON.stringify(this.currentUser, null, 2))
            }
        }
    }
    saveToLocalStorage() {
        this.debugMode && console.log("Saving CurrentUser Data to Local Storage...")
        // this.debugMode && console.log("Data To Save",JSON.stringify(this.currentUser,null,2))
        window.localStorage.setItem(loginDetailsKey, CryptoService.encrypt(JSON.stringify(this.currentUser)))
        this.debugMode && console.log("CurrentUser Data is saved to Local Storage.")
    }


    sendOtp(phone: string) {
        return axios.post(apiConfig.sendOtp, {
            phone: phone
        })
    }
    doSignUp(otp: string, payload: string) {
        const formData = new FormData()
        formData.append('payload', payload)
        formData.append('otp', otp)
        return axios.post(apiBaseUrl + "signup", formData).then((response) => {
            this.refresh(response)
        }).catch((err) => {
            this.debugMode && console.log("Error On Login")
            this.debugMode && console.log(JSON.stringify(err, null, 2));
            throw err
        })
    }
    requestSignUpOtp(formData: SignUpFormData, role_id: string, reCaptchToken: string) {
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            ...formData, role_id, reCaptchToken
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        } as RequestInit;

        return fetch(apiBaseUrl + "signup/token", requestOptions).then(response => {
            if (response.status == 200) {
                return response.text()
            } else {
                throw response.json()
            }
        })
    }

    makePostReady(object: any) {
        this.debugMode && console.log("makePostReady...")
        let sentenses = [] as string[]
        Object.keys((key: string) => {
            sentenses.push(key + "=" + object[key])
        })
        return sentenses.join("&")
        // return encodeURI(sentenses.join("&"))
    }

    privateApiCall(url: string, postValue: any = {}) {
        if (!this.currentUser.token) {
            this.debugMode && console.error("Calling Private Api without token...")
            return Promise.reject("Calling Private Api without token...")
        }
        this.debugMode && console.log("Calling Private Api with token...")
        this.debugMode && console.log("url", url)

        return axios.post(url, postValue, {
            headers: {
                'Authorization': "Bearer " + this.currentUser.token,
                'Content-Type': "application/json",
                'Accept': "application/json"
            }
        })
    }
    privateGetApiCall(url: string, params:any = false) {
        this.debugMode && console.log("Calling Private Get Api with token...")
        this.debugMode && console.log("url", url)

        const paramParts = [] as string[]
        if (params && typeof params === 'object') {
            Object.keys(params).map((key) => {
                if (params[key]) {
                    paramParts.push(encodeURIComponent(key) + "=" + encodeURIComponent(params[key]))
                }
            })
            if (paramParts) {
                url += "?" + paramParts.join("&")
            }
        }

        return axios.get(url, {
            headers: {
                'Authorization': "Bearer " + this.currentUser.token,
                'Content-Type': "application/json",
                'Accept': "application/json"
            }
        })
    }
    privatePutApiCall(url: string, dataToPut: any) {
        this.debugMode && console.log("Calling Private Put Api with token...")
        this.debugMode && console.log("url", url)

        return axios.put(url, dataToPut, {
            headers: {
                'Authorization': "Bearer " + this.currentUser.token,
                'Content-Type': "application/json",
                'Accept': "application/json"
            }
        })
    }
}

const loginService = new LoginServiceClass()
export default loginService
