import { IonCol, IonRow } from '@ionic/react';
import './PrimaryMenu.scss'
import { Link } from 'react-router-dom';
import { appSubDir } from '../../configs/app';
import { useEffect, useState } from 'react';
import { fetchCategories, localCategories } from '../../services/products/all-category.service';
import { getMultipleRandom } from '../../functions/array.functions';
import Category from '../../types/category.type';
import { preventOverflow } from '../../functions/string.functions';
import { useAppContext } from '../../services/app-context.service';

const PrimaryMenu: React.FC = () => {
    const appData = useAppContext()
    const [items, setItems] = useState(localCategories())
    useEffect(() => {
        if (items.length < 1) {
            fetchCategories().then((categories) => {
                setItems(categories)
            })
        }
    }, [])
    return (
        <nav id="primary-menu">
            <IonRow>
                <IonCol sizeXl='10' offsetXl='1' sizeLg='10' offsetLg='1' className='no-padding'>
                    <div className='body'>
                        <ul className='menu'>
                            <li>
                                <Link to={appSubDir + "about"}>About</Link>
                            </li>
                            {getMultipleRandom(
                                items.filter((item) => (item.subCategories.length > 5)),
                                window.screen.width <= 1024 ? 3 : 5
                            ).map((item: Category, index) => <li key={index}>
                                <Link to={appSubDir + "categories/" + encodeURIComponent(item.title)}>{preventOverflow(item.title, 15)}</Link>
                                <ul className='level level-1'>
                                    {getMultipleRandom(item.subCategories, 12).map((subCat, subCatIndex) => <li key={subCatIndex}>
                                        <Link to={appSubDir + "categories/" + encodeURIComponent(item.title) + "/sub-categories/" + encodeURIComponent(subCat)}>{preventOverflow(subCat, 15)}</Link>
                                    </li>)}
                                </ul>
                            </li>)}
                            <li>
                                <Link to={appSubDir + "contact"}>Contact</Link>
                            </li>
                        </ul>
                    </div>
                </IonCol>
            </IonRow>
        </nav>
    );
};

export default PrimaryMenu;
