import { IonButton, IonCol, IonRow, IonText } from '@ionic/react';
import { SimpleProduct } from '../../types/product.type';
import ProductCard from './ProductCard';
import { splitInHalf } from '../../functions/string.functions';
import { appSubDir } from '../../configs/app';
import ProductCardSkeletonLoading from './ProductCardSkeletonLoading';
type Props = {
    title: string
    hideHeader?: boolean
    splitTitle?: boolean
    products?: SimpleProduct[]
    loading: boolean
    routerLink?: string
}
const ProductsGrid: React.FC<Props> = ({ title, products, hideHeader, loading, routerLink, splitTitle }) => {
    if (!products) {
        return null
    }
    const titleParts = splitInHalf(title)
    return (
        <section className='h-products mb-50' style={{ padding: "0 10px" }}>
            {!hideHeader && <IonRow>
                <IonCol>
                    <div className='title'>
                        {splitTitle ? <>
                            <IonText color="primary" className='f'>{titleParts[0]}</IonText>
                            <IonText color="dark" className='s'> {titleParts[1]}</IonText>
                            <hr />
                        </> : <>
                            <IonText className='f'><h2 style={{ fontWeight: 600, letterSpacing: 1.3, color: 'var(--ion-color-link)' }}>{title}</h2></IonText>
                            <hr style={{ height: 3, width: "40%", margin: "4px 0", background: 'var(--ion-color-link)' }} />
                        </>}
                    </div>
                </IonCol>
                <IonCol className='text-end'>
                    {routerLink ? <IonButton routerLink={routerLink} fill='clear'>View All</IonButton> : <IonButton routerLink={appSubDir + "shop"} fill='clear' >See More</IonButton>}
                </IonCol>
            </IonRow>}
            {loading && <div className='product-grid'>
                {[...Array(18)].map((item, index) => <ProductCardSkeletonLoading key={index} />)}
            </div>}
            {!loading && products && <div className='product-grid'>
                {products.map((item, index) => <ProductCard key={index} product={item} />)}
            </div>}
        </section>
    );
};

export default ProductsGrid;
