import { Route } from 'react-router-dom';
import {
    IonMenu,
    IonPage,
    IonRouterOutlet
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Home from '../pages/Home';
import Menu from '../components/Menu';
import { appSubDir } from '../configs/app';
import About from '../pages/About';
import Contact from '../pages/Contact';
import Product from '../pages/products/Product';
import Category from '../pages/products/Category';
import SubCategory from '../pages/products/SubCategory';
import NotFound from '../pages/NotFound';
import PaymentVerify from '../pages/payments/PaymentVerify';
import PaymentSuccess from '../pages/payments/PaymentSuccess';
import PaymentFailed from '../pages/payments/PaymentFailed';
import PaymentCancelled from '../pages/payments/PaymentCancelled';
import { useAppContext } from '../services/app-context.service';
import TermsAndConditions from '../pages/TermsAndConditions';
import RefundPolicy from '../pages/RefundPolicy';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import BkashExecution from '../pages/payments/BkashExecution';
import HowToBuy from '../pages/HowToBuy';
import ShippingAndDelivery from '../pages/ShippingAndDelivery';
import ProhibitedItems from '../pages/ProhibitedItems';
import Search from '../pages/products/Search';
import SearchByImage from '../pages/products/SearchByImage';
import Invoices from '../pages/account/Invoices';
import Invoice from '../pages/account/Invoice';
import BkashDuePaymentExecution from '../pages/payments/BkashDuePaymentExecution';


const PrivateRoutes: React.FC = () => {
    const { user } = useAppContext()
    return <IonReactRouter>
        <IonMenu type='push' id="sidebar" contentId="menu">
            <Menu />
        </IonMenu>
        <input className='dummy' name="email" type="text" />
        <input className='dummy' type="password" />
        <IonPage id='menu'>
            <IonRouterOutlet>
                {/* Public Pages */}
                <Route exact path={appSubDir}>
                    <Home />
                </Route>
                <Route exact path={appSubDir + "about"}>
                    <About />
                </Route>
                <Route exact path={appSubDir + "contact"}>
                    <Contact />
                </Route>
                <Route exact path={appSubDir + "terms-and-conditions"}>
                    <TermsAndConditions />
                </Route>
                <Route exact path={appSubDir + "refund-policy"}>
                    <RefundPolicy />
                </Route>
                <Route exact path={appSubDir + "privacy-policy"}>
                    <PrivacyPolicy />
                </Route>
                <Route exact path={appSubDir + "how-to-buy"}>
                    <HowToBuy />
                </Route>
                <Route exact path={appSubDir + "shipping-and-delivery"}>
                    <ShippingAndDelivery />
                </Route>
                <Route exact path={appSubDir + "prohibited-Items"}>
                    <ProhibitedItems />
                </Route>
                <Route exact path={appSubDir + "search/:keyword"}>
                    <Search />
                </Route>
                <Route exact path={appSubDir + "search-by-image/:fileName"}>
                    <SearchByImage />
                </Route>
                <Route exact path={appSubDir + "product/abb-:ID"}>
                    <Product />
                </Route>
                <Route exact path={appSubDir + "categories/:cat"}>
                    <Category />
                </Route>
                <Route exact path={appSubDir + "categories/:cat/sub-categories/:subCat"}>
                    <SubCategory />
                </Route>
                <Route exact path={appSubDir + "payment-verify/:valID"}>
                    <PaymentVerify />
                </Route>
                <Route exact path={appSubDir + "payment/success"}>
                    <PaymentSuccess />
                </Route>
                <Route exact path={appSubDir + "payment/failed"}>
                    <PaymentFailed />
                </Route>
                <Route exact path={appSubDir + "payment/cancelled"}>
                    <PaymentCancelled />
                </Route>
                {/* Private Pages */}
                <Route exact path={appSubDir + "invoices"}>
                    <Invoices />
                </Route>
                <Route exact path={appSubDir + "invoices/:invoiceID"}>
                    <Invoice />
                </Route>
                <Route exact path={appSubDir + "acount"}>
                    <About />
                </Route>
                <Route exact path={appSubDir + "payment-verify/:valID"}>
                    <PaymentVerify />
                </Route>
                <Route exact path={appSubDir + "payment/success"}>
                    <PaymentSuccess />
                </Route>
                <Route exact path={appSubDir + "payment/failed"}>
                    <PaymentFailed />
                </Route>
                <Route exact path={appSubDir + "payment/cancelled"}>
                    <PaymentCancelled />
                </Route>
                <Route exact path={appSubDir + "bkash-payment-execution"}>
                    <BkashExecution />
                </Route>
                <Route exact path={appSubDir + "bkash-due-payment"}>
                    <BkashDuePaymentExecution />
                </Route>
                <Route>
                    <NotFound />
                </Route>
            </IonRouterOutlet>
        </IonPage>
    </IonReactRouter>
};

export default PrivateRoutes;
