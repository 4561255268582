import { IonCol, IonContent, IonRow, IonText, IonTitle, useIonViewDidEnter } from '@ionic/react';
import Header from '../components/Header/Header';
import { onScroll } from '../services/sticky-menu.service';
import Footer from '../components/Footer';

const ShippingAndDelivery: React.FC = () => {
    useIonViewDidEnter(() => {
        const tabBar = document.querySelector('ion-tab-bar');
        if (tabBar !== null) {
            tabBar.style.display = 'none';
        }
    })
    return (
        <IonContent fullscreen scrollEvents={true} onIonScroll={onScroll}>
            <Header />
            <IonRow>
                <IonCol sizeXl='10' offsetXl='1' sizeLg='10' offsetLg='1' className='no-padding bg-content'>
                    <div style={{ padding: 20, minHeight: '80vh' }}>

                        <IonTitle><h1 className='text-center'>Shipping & Delivery</h1></IonTitle>
                        <IonText><hr />

                            <p>আমরা যেহেতু B2B বিজনেস করি তাই বানিজ্যিক উদ্দেশ্যে আনা পন্যের জন্য অবশ্যই আপনাকে কাস্টমস ট্যাক্স ও চায়না থেকে বাংলাদেশে শিপিং চার্জ প্রদান করতে হবে। আপনি অর্ডার করার সময় পন্যের মুল্য ও শিপিং/কাষ্টমস চার্জ প্রতি কেজির রেট দেখাবে। যা পণ্য আসার পর প্রকৃত ওজনের উপর হিসাব হবে।</p>

                            <p>কাস্টমস ও শিপিং চার্জঃ কাস্টমস ও শিপিং চার্জ পন্য/অর্ডার ভ্যালুর উপর কম/বেশি হয়ে থাকে। যেহেতু আমাদের সকল পণ্য মিক্সড হয় তাই এভারেজ করে রেট নির্ধারন করা হয় যা আমাদের পণ্যের পেজে  Shipping Charge এর কলামে প্রতি কেজি রেট উল্লেখ থাকে এবং পণ্য আসার পর উল্লখিত রেট অনুযায়ী চার্জ করা হয়। শিপিং রেট পন্যের ক্যাটাগরি, অর্ডার ভ্যালু, অর্ডারের পরিমান এর উপর নির্ভর করে।</p>

                            <p>কাস্টমস ও শিপিং চার্জ প্রতিনিয়ত পরিবর্তন হয়ে থাকে এয়ার ভাড়া, CNF চার্জ ও বিভিন্ন কারনে, তবে আপনি যখন অর্ডার করবেন তখন যে রেট আমাদের সাইটে দেখাবে সেটাই পণ্য আসার পর কার্যকর থাকবে। তাই আপনি নিশ্চিন্তে অর্ডার করতে পারবেন আমাদের সাইট থেকে।</p>

                            <p>ওজন হিসাবঃ  আমাদের বেশিরভাগ ব্যবসা হয় যারা নতুন উদ্যোক্তা বা ছোট/মাঝারী ব্যবসায়ী। তাই আমাদের অর্ডারগুলি হয় ছোট সাইজের। কিন্তু পন্যগুলি চায়না থেকে বড় কার্টুনে রি-প্যাকিং করে তারপর শিপমেন্ট করতে হয়। উক্ত বড় কার্টুনের জন্য আমাদের চায়নাতে পেমেন্ট করতে হয় এবং এয়ার/শিপ/কাস্টমস সবাইকে টোটাল ওজনের উপরে পেমেন্ট করতে হয়। কিন্তু আমাদের বেশিরভাগ কাস্টমার এই বড় কার্টুনের ভাগ নিতে চান না। তাই আমাদের প্রতিটি পণ্য আলাদা করে ওজন করতে হয়। আমাদের প্রতিটি প্যাকেট এর ওজন আলাদা হবে ১০০ গ্রাম এর গূনিতক হিসাব করা হয়। যেমন আপনার কোন প্যাকেট এর ওজন যদি ১০০ গ্রাম হয়, তাহলে ১০০ গ্রামই শিপিং চার্জ হিসাব হবে। যদি আপনার একাধিক অর্ডার থাকে তাহলেও প্রতি প্যাকেট আলাদা আলাদা ওজন হিসাব হবে।</p>
                        </IonText>
                    </div>
                </IonCol>
            </IonRow>
            <Footer />
        </IonContent>
    );
};

export default ShippingAndDelivery;
