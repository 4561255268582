import { IonItem, IonText } from '@ionic/react';
import { SimpleProduct } from '../../types/product.type';
import './ProductListItem.scss'
import { useRef } from 'react';
import { getPriceFactor, getProductImageUrl } from '../../functions/product.functions';
import { useAppContext } from '../../services/app-context.service';
type Props = {
    product: SimpleProduct
}
const ProductListItem: React.FC<Props> = ({ product }) => {
    const modal = useRef<HTMLIonModalElement>(null)
    const data = useAppContext()
    const priceFactor = getPriceFactor(data.settings)
    return <IonItem className='product' onClick={() => modal.current?.present()}>
        <img src={getProductImageUrl(product.img,100)} slot='start' alt="" loading='lazy' />
        <div className='name'>
            <IonText>{product.title}</IonText>
        </div>
        <div className='pricing'>
            {/* <IonText className='regular'>{product.price} </IonText> */}
            <IonText className='sale' color='primary'>{Math.ceil(parseFloat(product.price) * priceFactor)}/=</IonText>
        </div>
    </IonItem>
};

export default ProductListItem;
