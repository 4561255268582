import { Link } from 'react-router-dom';
import { appSubDir } from '../../configs/app';
import { IonCol, IonRow } from '@ionic/react';
import CartModal from '../Shop/CartModal';
import AuthModal from '../Auth/AuthModal';
import './Header.scss'
import SearchBar from './SearchBar';
import PrimaryMenu from './PrimaryMenu';
import QuickLinksModal from './QuickLinksModal';

const Header: React.FC = () => {

    return <>
        {/* <TopBar /> */}
        <header>
            <IonRow>
                <IonCol sizeXl='10' offsetXl='1' sizeLg='10' offsetLg='1'>
                    <IonRow className='desktop'>
                        <IonCol size='12' sizeMd='4'>
                            <Link to={appSubDir} className='brand'>
                                <img src="icon-192x192.webp" alt='Brand Logo' />
                                <h1>China Hub BD</h1>
                            </Link>
                        </IonCol>
                        <IonCol size='12' sizeMd='5'>
                            <SearchBar />
                        </IonCol>
                        <IonCol size='12' sizeMd='3'>
                            <div className='buttons'>
                                <CartModal />
                                <AuthModal />
                                <QuickLinksModal />
                            </div>
                        </IonCol>
                    </IonRow>
                    <IonRow className='tab'>
                        <IonCol size='6'>
                            <Link to={appSubDir} className='brand'>
                                <img src="assets/icon/logo-rectangle.webp" alt='Brand Logo' />
                                <h1>China Hub BD</h1>
                            </Link>
                        </IonCol>
                        <IonCol size='6'>
                            <div className='buttons'>
                                <CartModal />
                                <AuthModal />
                                <QuickLinksModal />
                            </div>
                        </IonCol>
                        <IonCol size='12'>
                            <SearchBar />
                        </IonCol>
                    </IonRow>
                    <IonRow className='mobile'>
                        <Link to={appSubDir} className='brand'>
                            <img src="assets/icon/logo-rectangle.webp" alt='Brand Logo' />
                            <h1>China Hub BD</h1>
                        </Link>
                    </IonRow>
                </IonCol>
            </IonRow>
        </header>
        {window.screen.width > 600 ? <PrimaryMenu /> : <SearchBar />}
    </>
};

export default Header;
