import { IonCol, IonIcon, IonRow, IonText } from '@ionic/react';
import './Footer.scss'
import { faRefresh, faStar, faTruck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { appSubDir } from '../configs/app';
import MobileMenu from './MobileMenu';
import { location, mailOpen, phonePortrait } from 'ionicons/icons';
import { useAppContext } from '../services/app-context.service';

const Footer: React.FC = () => {
    const data = useAppContext()

    return <>
        <footer>
            <div className="about">
                <IonRow>
                    <IonCol sizeXl='10' offsetXl='1' sizeLg='10' offsetLg='1'>
                        <IonRow>
                            <IonCol sizeXl='3' sizeLg='6' sizeMd='6' size='12'>
                                <h1>China Hub BD</h1>
                                <hr />
                                <address className='item'><IonIcon color='dark' icon={location} />{data.settings?.company_address}</address>
                                <div className='item'><a href='tel:+880 170 933 9369'><IonIcon color='dark' icon={phonePortrait} />{data.settings?.company_phone}</a></div>
                                <div className='item'><a href='mailto:infochinahubbd@gmail.com'><IonIcon color='dark' icon={mailOpen} />{data.settings?.company_email}</a></div>

                            </IonCol>
                            <IonCol sizeXl='3' sizeLg='6' sizeMd='6' size='12'>
                                <h1>Important Links</h1>
                                <hr />
                                <div className='links'>
                                    <Link to={appSubDir + "about"}>About Us</Link>
                                    <Link to={appSubDir + "contact"}>Contact Us</Link>
                                    <Link to={appSubDir + "terms-and-conditions"}>Terms & Conditions</Link>
                                    <Link to={appSubDir + "privacy-policy"}>Privacy Policy</Link>
                                </div>
                            </IonCol>
                            <IonCol sizeXl='3' sizeLg='6' sizeMd='6' size='12'>
                                <h1>Customer Service</h1>
                                <hr />
                                <div className='links'>
                                    <Link to={appSubDir + "how-to-buy"}>How To Buy</Link>
                                    <Link to={appSubDir + "refund-policy"}>Return & Refund Policy</Link>
                                    <Link to={appSubDir + "shipping-and-delivery"}>Shipping & Delivery</Link>
                                    <Link to={appSubDir + "prohibited-Items"}>Prohibited Items</Link>
                                </div>
                            </IonCol>
                            <IonCol sizeXl='3' sizeLg='6' sizeMd='6' size='12'>
                                <h1>Our Partners</h1>
                                <hr />
                                <div>
                                    <img src="assets/img/our-partners.webp" alt='Our Partners' loading='lazy' />
                                </div>

                            </IonCol>
                        </IonRow>
                    </IonCol>
                </IonRow>
            </div>
            <div className='copyright'>
                China Hub BD &copy; {new Date().getFullYear()}, All Rights Reserved.
            </div>
        </footer>
        {window.screen.width < 600 ? <MobileMenu /> : null}
    </>
};

export default Footer;
