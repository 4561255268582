import {  IonContent, IonHeader, IonIcon,  IonItem,  IonText, IonTitle, IonToolbar,  useIonViewDidEnter } from '@ionic/react';
import {  home, paperPlane } from 'ionicons/icons';
import { apiBaseUrl } from '../configs/app';
import { useAppContext} from '../services/app-context.service';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import User from '../types/user.type';
import { UserClass } from '../services/user.services';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCarSide, faFileInvoice, faUsers } from '@fortawesome/free-solid-svg-icons'

const Menu: React.FC = () => {
  const data = useAppContext() as any
  const [user,setUser] = useState(data.user as User)
  const history = useHistory()
  function fetchData(){
    const userObj = new UserClass(data.user)
    userObj.fetchFromOnline()?.then((userDetails:User)=>{
      setUser(userDetails)
    })
  }
  useIonViewDidEnter(()=>{
    fetchData()
    const tabBar = document.querySelector('ion-tab-bar');
    if (tabBar !== null) {
      tabBar.style.display = 'flex';
    }
  })
  async function afterLogout(){
    window.localStorage.clear()
    history.replace('/')
  }
  function logout(){
    if(data.user.token){
      const myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer "+data.user.token);
      myHeaders.append("Accept", "application/json");

      const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      fetch(apiBaseUrl+"logout", requestOptions as RequestInit)
        .then(response => response.text())
        .then(result => console.log(result))
        .catch(error => console.error('error', error))
        .finally(()=>{
          window.localStorage.removeItem('user')
          data.user.token = ""
          afterLogout()
        })
    }else{
      afterLogout()
    }
  }
  return (
    <>
      <IonHeader>
        <IonToolbar>
          <img slot='start' style={{height:40,marginLeft:10}} src='assets/icon/icon.webp' alt="" loading='lazy'/>
          <IonTitle>China Hub BD</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonItem routerLink='home'>
          <IonIcon slot='start' icon={home}></IonIcon>
          <h5>Dashboard</h5>
        </IonItem>
        <IonItem routerLink='reservations'>
          <IonIcon slot='start' icon={paperPlane}></IonIcon>
          <h5>Reservations</h5>
        </IonItem>
        <IonItem routerLink='home'>
          <IonText slot='start'><FontAwesomeIcon icon={faCarSide} /></IonText>
          <h5>Dispatch</h5>
        </IonItem>
        <IonItem routerLink='home'>
          <IonText slot='start' style={{width:25,textAlign:"center"}}><FontAwesomeIcon icon={faFileInvoice} /></IonText>
          <h5>Invoices</h5>
        </IonItem>
        <IonItem routerLink='users'>
          <IonText slot='start'><FontAwesomeIcon icon={faUsers} /></IonText>
          <h5>User Management</h5>
        </IonItem>
      </IonContent>
    </>
  );
};

export default Menu;
