import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import ReactGA from "react-ga4";
import ReactPixel, { AdvancedMatching } from 'react-facebook-pixel';
import { facebookPixelId, ga4MeasurementId } from './configs/config.private';

const advancedMatching = {} as AdvancedMatching; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
};
if (facebookPixelId)
    ReactPixel.init(facebookPixelId, advancedMatching, options);

if (ga4MeasurementId)
    ReactGA.initialize(ga4MeasurementId);

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// const mapScript = document.createElement("script");
// mapScript.setAttribute("src", "https://maps.googleapis.com/maps/api/js?key="+googleApiKey+"&libraries=places");
// document.body.appendChild(mapScript);
// mapScript.addEventListener("load", () => {
//     console.log("File loaded")
// })