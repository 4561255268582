import {
    IonButton, IonCheckbox, IonContent, IonFooter, IonHeader, IonInput, IonItem, IonLabel,
    IonNote, IonTextarea, IonTitle, IonToolbar, useIonToast
} from '@ionic/react';


import { useAppContext, useAppContextUpdater } from '../../services/app-context.service';
import './CheckoutBody.scss'
import { useRef, useState } from 'react';
import { Formik, FormikHelpers } from 'formik';
import shoppingCartService from '../../services/shop/cart.service';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { checkoutModalRef } from './CartModal';
import { appSubDir, recaptchaSiteKey } from '../../configs/app';
import { useHistory } from 'react-router';
import CartTotal from './CartTotal';
import GoogleReCaptcha from '../GoogleReCaptcha';
import { bkashPaymentCreate } from '../../services/bkash-payment-service';
type FormFields = {
    name: string
    phone: string
    address: string
    note: string
    saveShippingDetails: boolean
}
const CheckoutBody: React.FC = () => {
    const history = useHistory()
    const appData = useAppContext()
    const setAppData = useAppContextUpdater()
    const { user, cart } = appData
    const submitBtnRef = useRef<HTMLInputElement>(null)
    const checkboxRef = useRef<HTMLInputElement>(null)
    const [reCaptchaToken, setReCaptchaToken] = useState<string | null>(null)
    const [isFormSubmitting, setIsFormSubmitting] = useState(false)
    const isReCaptchRequired = !user.token && !reCaptchaToken
    const [presentToast] = useIonToast();
    function validator(values: FormFields) {
        const errors = {} as FormFields;
        if (!values.name) {
            errors.name = 'Required';
        } else if (
            values.name.includes("@")
        ) {
            errors.name = 'Invalid Name address';
        }

        if (!values.phone) {
            errors.phone = 'Required';
        } else if (
            values.phone.length != 11
        ) {
            errors.phone = '11 Digit Required';
        } else if (
            !values.phone.match(/^(01){1}[3-9]{1}\d{8}/g)
        ) {
            errors.phone = 'Invalid Phone Number';
        }

        if (!values.address) {
            errors.address = 'Required';
        }
        updateCart(values)
        return errors;
    }
    function updateCart(values: FormFields) {
        let cartUpdated = false
        if (cartUpdated) {
            shoppingCartService.saveCart()
            appData.cart = shoppingCartService.shoppingCart
            setAppData({ ...appData })
        }
    }
    function saveCart(values: FormFields) {
        // console.log(values);

        // console.log("paymentMethod", values.paymentMethod)
        // reCaptchaRef.current?.reset()
        shoppingCartService.shoppingCart.s_name = values.name
        shoppingCartService.shoppingCart.s_phone = values.phone
        shoppingCartService.shoppingCart.s_address = values.address
        shoppingCartService.shoppingCart.s_note = values.note
        shoppingCartService.shoppingCart.saveShippingDetails = values.saveShippingDetails
        shoppingCartService.saveCart()
    }
    function onCashOnDelivery(values: FormFields, formikHelpers: FormikHelpers<FormFields>) {
        const { setSubmitting } = formikHelpers

        saveCart(values)
        setSubmitting(true);
        setIsFormSubmitting(true)
        shoppingCartService.makeOrder().then((response) => {
            if (response.data.invoice_id) {
                shoppingCartService.clearCart()
                setAppData({ ...appData, cart: shoppingCartService.shoppingCart })
                checkoutModalRef.current?.dismiss()
                presentToast({
                    message: "Order Placed Successfully.",
                    duration: 3000,
                    cssClass: 'custom-toast',
                    animated: true,
                    color: "success",
                    buttons: [
                        {
                            text: 'Dismiss',
                            role: 'cancel'
                        }
                    ],
                })
                history.replace(appSubDir + "invoices/" + response.data.invoice_id)
            } else if (response.data.gatewayPageURL) {
                window.location.href = response.data.gatewayPageURL
            }
        }).catch((error) => {
            presentToast({
                message: error.response.data.error ? error.response.data.error : "Something Went Wrong.",
                duration: 3000,
                cssClass: 'custom-toast',
                animated: true,
                color: "danger",
                buttons: [
                    {
                        text: 'Dismiss',
                        role: 'cancel'
                    }
                ],
            })
        }).finally(() => {
            setSubmitting(false);
            setIsFormSubmitting(false)
        })
    }
    function onSubmit(values: FormFields, formikHelpers: FormikHelpers<FormFields>) {
        const { setSubmitting } = formikHelpers

        saveCart(values)
        setSubmitting(true);
        setIsFormSubmitting(true)
        bkashPaymentCreate().catch((error) => {
            console.log("error",error)
            presentToast({
                message: error.response.data.message ? error.response.data.message : "Something Went Wrong.",
                duration: 3000,
                cssClass: 'custom-toast',
                animated: true,
                color: "danger",
                buttons: [
                    {
                        text: 'Dismiss',
                        role: 'cancel'
                    }
                ],
            })
        }).finally(() => {
            setSubmitting(false);
            // setIsFormSubmitting(false)
        })
    }
    return (
        <>
            <IonHeader>
                <IonToolbar color="light">
                    <IonTitle>Shipping Information</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent color="light" className='ion-padding' style={{ overflow: "auto" }}>
                <Formik
                    initialValues={{
                        name: cart.s_name,
                        phone: cart.s_phone,
                        address: cart.s_address,
                        note: cart.s_note,
                        saveShippingDetails: !!cart.saveShippingDetails
                    } as FormFields}
                    validate={validator}
                    onSubmit={onSubmit}>
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting
                        /* and other goodies */
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <IonItem className={errors.name && touched.name ? 'ion-invalid' : undefined}>
                                <IonLabel position="floating">Name</IonLabel>
                                <IonInput name='name'
                                    onIonChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.name} />
                                {errors.name && touched.name && <IonNote slot='error'>{errors.name}</IonNote>}
                            </IonItem>
                            <IonItem className={errors.phone && touched.phone ? 'ion-invalid' : undefined}>
                                <IonLabel position="floating">Phone</IonLabel>
                                <IonInput name='phone' placeholder='01XXXXXXXXX (BD Mobile Number Only)' min={1000000000} max={1999999999}
                                    onIonChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.phone} />
                                {errors.phone && touched.phone && <IonNote slot='error'>{errors.phone}</IonNote>}
                            </IonItem>

                            <IonItem className={errors.address && touched.address ? 'ion-invalid' : undefined}>
                                <IonLabel position="floating">Address</IonLabel>
                                <IonTextarea name='address'
                                    onIonChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.address} />
                                {errors.address && touched.address && <IonNote slot='error'>{errors.address}</IonNote>}
                            </IonItem>

                            <IonItem className={errors.note && touched.note ? 'ion-invalid' : undefined}>
                                <IonLabel position="floating">Note</IonLabel>
                                <IonTextarea name='note'
                                    onIonChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.note} />
                                {errors.note && touched.note && <IonNote slot='error'>{errors.note}</IonNote>}
                            </IonItem>
                            <IonItem className={errors.saveShippingDetails && touched.saveShippingDetails ? 'ion-invalid' : undefined}>
                                <IonCheckbox slot='end' onIonChange={(e) => checkboxRef.current?.click()} />
                                <IonLabel>
                                    Save Shipping Details
                                    <input style={{ display: 'none' }} slot='end' type='checkbox' name='saveShippingDetails'
                                        onChange={handleChange} ref={checkboxRef} />
                                </IonLabel>
                                {errors.saveShippingDetails && touched.saveShippingDetails && <IonNote slot='error'>{errors.saveShippingDetails}</IonNote>}
                            </IonItem>
                            <input style={{ display: 'none' }} disabled={isSubmitting} type='submit' ref={submitBtnRef} />
                        </form>
                    )}
                </Formik>
                {isReCaptchRequired && <div style={{ display: "flex", justifyContent: "center", padding: '20px 0 0' }}>
                    <GoogleReCaptchaProvider reCaptchaKey={recaptchaSiteKey}>
                        <GoogleReCaptcha
                            action='guestUserCheckout'
                            onVerify={setReCaptchaToken}
                        />
                    </GoogleReCaptchaProvider>
                </div>}
            </IonContent>
            {cart.itemCount > 0 && !isReCaptchRequired && <IonFooter>
                <CartTotal />
                <IonButton disabled={isFormSubmitting} onClick={() => {
                    submitBtnRef.current?.click()
                }} color="primary" expand="block">{
                        isFormSubmitting ?
                            "Processing...." :
                            "Pay With Bkash"
                    }</IonButton>
            </IonFooter>}

        </>
    );
};

export default CheckoutBody;
