import { apiConfig } from "../../configs/app";
import Shop from "../../types/shop.type";
import CryptoService from "../crypto.services";

type ApiResponse = {
    code: number
    data: Shop
}
function getKey(memberId: string) {
    return CryptoService.hash('product-shop-' + memberId)
}
export function fromLocal(memberId: string) {
    const localStorageKey = getKey(memberId)
    const localData = window.localStorage.getItem(localStorageKey)
    return (localData ? JSON.parse(CryptoService.decrypt(localData)) as Shop : null)
}
export function fromServer(memberId: string) {
    const localStorageKey = getKey(memberId)
    let promise: Promise<Response>
    promise = fetch(apiConfig.shop + '/' + memberId)
    return promise.then((res) => res.json())
        .then((res: ApiResponse) => {
            if (res.code === 200) {
                window.localStorage.setItem(localStorageKey, CryptoService.encrypt(JSON.stringify(res.data)))
                return res.data
            } else {
                return null
            }
        })
}
