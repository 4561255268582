import { IonCol, IonContent, IonRow, IonSpinner, IonText, useIonViewDidEnter } from '@ionic/react';
import Header from '../../components/Header/Header';
import { onScroll } from '../../services/sticky-menu.service';
import Footer from '../../components/Footer';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { fetchProduct, getLocalProduct } from '../../services/products/product.service';
import ProductBody from '../../components/Products/ProductBody';
import { trackPageView } from '../../functions/analytics.functions';
import { documentBaseTitle } from '../../configs/app';
type CustomParam = {
    ID: string
}
const Product: React.FC = () => {
    const { ID } = useParams() as CustomParam
    const localProduct = getLocalProduct(ID)
    const [item, setItem] = useState(localProduct ? localProduct.data : null)
    const [loading, setLoading] = useState(!localProduct)
    useIonViewDidEnter(() => {
        trackPageView(ID + " | Product")
    })
    useEffect(() => {
        if (!localProduct) {
            setLoading(true)
        }
        fetchProduct(ID).then(product => {
            if (product) {
                setItem(product.data)
                document.getElementsByTagName("title")[0].innerHTML = product.data.title + " | Product | " + documentBaseTitle
            }
        }).finally(() => {
            setLoading(false)
        })
    }, [ID])
    return (
        <IonContent fullscreen scrollEvents={true} onIonScroll={onScroll}>
            <Header />
            <IonRow>
                <IonCol sizeXl='10' offsetXl='1' sizeLg='10' offsetLg='1' className='ion-padding bg-content'>
                    <div className='page-container'>
                        {loading && <div className='box-center'><IonSpinner /></div>}
                        {!loading && !item && <div className='box-center'><IonText><h1>Something Went Wrong</h1></IonText></div>}
                        {!loading && item && <ProductBody item={item} />}
                    </div>
                </IonCol>
            </IonRow>
            <Footer />
        </IonContent>
    );
};

export default Product;
