import { IonButton } from '@ionic/react';
import { useEffect, useState } from 'react';
import { appSubDir } from '../../configs/app';
import ProductsGrid from './ProductsGrid';
import Pagination from '../Pagination';
import { fetchProducts, getProducts } from '../../services/products/search-products-by-url.service';
type Props = {
    fileName: string
}
const ImageSearchResult: React.FC<Props> = ({ fileName }) => {
    const productResponse = getProducts(fileName)
    const [items, setItems] = useState(productResponse ? productResponse.data.items : null)
    const [page, setPage] = useState(1)
    const [lastPage, setLastPage] = useState((productResponse && productResponse.data.total_count) ? Math.ceil((parseInt(productResponse.data.total_count) || 0) / productResponse.data.page_size) : null)
    const [loading, setLoadling] = useState(false)
    useEffect(() => {
        const local = getProducts(fileName, page)
        if (local) {
            setItems(local.data.items)
            if (local.data.total_count) {
                setLastPage(Math.ceil(parseInt(local.data.total_count) / local.data.page_size))
            } else {
                setLastPage(null)
            }
            setPage(local.data.page)
        }
        setLoadling(true)
        fetchProducts(fileName, page).then((res) => {
            if (res) {
                setItems(res.data.items)
                if (res.data.total_count) {
                    setLastPage(Math.ceil(parseInt(res.data.total_count) / res.data.page_size))
                } else {
                    setLastPage(null)
                }
                setPage(res.data.page)
            }
        }).finally(() => setLoadling(false))
    }, [fileName, page])
    const loadProducts = (pageNo: number) => {
        setPage(pageNo)
    }
    return <>
        <div style={{ marginTop: 30 }}>
            {<ProductsGrid title='Products' products={items ? items : []} hideHeader={true} loading={loading} />}
            {(lastPage && lastPage > 1) && <Pagination active={page} disabled={loading} onClick={loadProducts} buttonCount={lastPage} />}
        </div>
        {!items && !loading && <div className='text-center'>
            <h1 style={{ marginTop: 100, marginBottom: 100 }}>Sorry ! Nothing Found</h1>
            <IonButton routerLink={appSubDir} fill='outline'>
                Go To Home
            </IonButton>
        </div>}
    </>
};

export default ImageSearchResult;
