import { IonButton, IonCol, IonContent, IonFooter, IonHeader, IonItem, IonModal, IonRow, IonTitle, IonToolbar, useIonViewDidEnter } from '@ionic/react';
import Header from '../../components/Header/Header';
import { onScroll } from '../../services/sticky-menu.service';
import Footer from '../../components/Footer';
import { useRef } from 'react';
import { localCategories } from '../../services/products/all-category.service';
import { useParams } from 'react-router';
import { appSubDir } from '../../configs/app';
import ProductGridWithPagination from '../../components/Products/ProductGridWithPagination';
import { trackPageView } from '../../functions/analytics.functions';
type CustomParam = {
    cat: string
}
const Category: React.FC = () => {
    const { cat } = useParams() as CustomParam
    const categories = localCategories()
    const category = categories.find((i) => i.title === cat)
    const modal = useRef<HTMLIonModalElement>(null)
    useIonViewDidEnter(() => {
        trackPageView(cat + " | Category")
    })
    return (
        <IonContent fullscreen scrollEvents={true} onIonScroll={onScroll}>
            <Header />
            <IonRow>
                <IonCol sizeXl='10' offsetXl='1' sizeLg='10' offsetLg='1' className='ion-padding bg-content'>
                    <div className='page-container'>
                        <div className='page-container'>
                            <div className='title-bar'>
                                <h6>Category</h6>
                                <h1>{decodeURIComponent(cat)}</h1>
                                {category && category.subCategories.length > 0 && <IonButton onClick={() => modal.current?.present()} fill='outline'>All Sub Categoies</IonButton>}
                            </div>
                            <ProductGridWithPagination keyword={cat} />
                            {category && category.subCategories.length > 0 && <IonModal ref={modal}>
                                <IonHeader>
                                    <IonToolbar>
                                        <IonTitle>Sub Categories</IonTitle>
                                        {window.screen.width > 425 && <IonButton onClick={() => modal.current?.dismiss()} slot='end' fill='clear'>Close</IonButton>}
                                    </IonToolbar>
                                </IonHeader>
                                <IonContent>
                                    {category && category.subCategories.map((item, index) => <IonItem key={index} routerLink={appSubDir + "categories/" + encodeURIComponent(category.title) + "/sub-categories/" + encodeURIComponent(item)}>{item}</IonItem>)}
                                </IonContent>
                                {window.screen.width <= 425 && <IonFooter>
                                    <IonButton onClick={() => modal.current?.dismiss()} expand='block' fill='clear'>Close</IonButton>
                                </IonFooter>}
                            </IonModal>}
                        </div>
                    </div>
                </IonCol>
            </IonRow>
            <Footer />
        </IonContent>
    );
};

export default Category;
