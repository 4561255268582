import { IonCard, IonCardContent, IonText } from '@ionic/react';
import { SimpleProduct } from '../../types/product.type';
import './ProductCard.scss'
import { getProductRawPrice, getProductUrl, getProductImageUrl, getPriceFactor } from '../../functions/product.functions';
import { useAppContext } from '../../services/app-context.service';
type Props = {
    product: SimpleProduct
}
const ProductCard: React.FC<Props> = ({ product }) => {
    const data = useAppContext()
    const priceFactor = getPriceFactor(data.settings)
    return <IonCard routerLink={getProductUrl(product.item_id)} className='product'>
        <IonCardContent>
            <div className="text-center">
                <img src={getProductImageUrl(product.img, 250)} alt="" loading='lazy' />
            </div>

            <h2>{product.title}</h2>
            <div className='sale-info'>
                {/* <IonText className='regular'>৳ {product.regular_price}</IonText> */}
                <IonText className='price' color='primary'>৳ {getProductRawPrice(product.price, priceFactor)}</IonText>
                {!!product.sale_info.sale_quantity && <IonText className='sold' color='primary'>{getProductRawPrice(product.sale_info.sale_quantity, priceFactor)} Sold</IonText>}
            </div>
        </IonCardContent>
    </IonCard>
};

export default ProductCard;
