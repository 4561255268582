import { IonButton, IonContent, IonInput, IonItem, IonPopover } from "@ionic/react";
import "./CartProduct.scss";
import { CartItem } from "../../types/shop/cart.type";
import shoppingCartService from "../../services/shop/cart.service";
import { useAppContext, useAppContextUpdater } from "../../services/app-context.service";
import { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { getPriceFactor } from "../../functions/product.functions";

type Props = {
    itemIndex: number
    cartItem: CartItem
    children: JSX.Element[]
    className?: string
}
const CartProductEdit: React.FC<Props> = ({ itemIndex, cartItem, children, className }) => {
    const data = useAppContext()
    const SetData = useAppContextUpdater()
    const input = useRef<HTMLIonInputElement>(null)
    const minQuantity = parseInt(cartItem.sku_price_range.begin_num) || 1
    const priceFactor = getPriceFactor(data.settings)
    function onUpdateCart() {
        data.cart = shoppingCartService.shoppingCart
        SetData({ ...data })
    }
    function getInputValue() {
        if (input.current && input.current.value) {
            if (typeof input.current.value == "string") {
                return parseInt(input.current.value) || 0
            } else {
                return input.current.value
            }
        }
        return 0
    }
    function onChange() {
        const selectedQuantity = getInputValue()
        if (selectedQuantity > 0) {
            shoppingCartService.updateQuantity(itemIndex, Math.max(selectedQuantity, minQuantity),priceFactor)
        }
        onUpdateCart()
    }
    function onIncreament() {
        shoppingCartService.addToCart(cartItem, 1,priceFactor)
        onUpdateCart()
    }
    function onDecreament() {
        if (cartItem.quantity > minQuantity) {
            shoppingCartService.addToCart(cartItem, -1,priceFactor)
            onUpdateCart()
        }
    }
    return <>
        <div className={className} id={`cart-cartItem-${itemIndex}`}>{children}</div>
        <IonPopover trigger={`cart-cartItem-${itemIndex}`} triggerAction="click">
            <IonContent class="ion-padding">
                <IonItem>
                    <IonButton fill='outline' slot="start" onClick={onDecreament}><FontAwesomeIcon icon={faMinus} /></IonButton>
                    <IonInput ref={input} onIonChange={onChange} debounce={1000} style={{ textAlign: "center" }} value={cartItem.quantity} type='number' />
                    <IonButton fill='outline' slot="end" onClick={onIncreament}><FontAwesomeIcon icon={faPlus} /></IonButton>
                </IonItem>
                <IonButton expand="block">Update</IonButton>
            </IonContent>
        </IonPopover>
    </>

}
export default CartProductEdit
