import { baseUrl } from '../../configs/app';
import HomeSection, { HomeItem } from '../../types/home.type';
import { Navigation, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

const SlidesMini: React.FC = () => {
    return (
        <Swiper
            autoplay={{
                pauseOnMouseEnter: true,
                delay: 3000
            }}
            pagination={{
                clickable: true,
            }}
            loop={true}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            className="mini-slider">
            {[...Array(6)].map((item, index) => <SwiperSlide key={index}>
                <img src={encodeURIComponent('assets/img/slider/banner (' + (index + 1) + ').webp')} alt="" loading='lazy' />
            </SwiperSlide>)}
        </Swiper>
    );
};

export default SlidesMini;
