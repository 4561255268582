import { IonIcon, IonItem, IonText, useIonToast } from "@ionic/react";
import { useAppContext, useAppContextUpdater } from "../../services/app-context.service";
import { document, power } from "ionicons/icons";
import loginService from "../../services/loginService";
import { authModalRef } from "./AuthModal";
import { apiBaseUrl, appSubDir } from "../../configs/app";
import { faDashboard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const UserMenu: React.FC = () => {
    const appData = useAppContext()
    const isAdmin = appData.user.roles.includes("admin")
    const setAppData = useAppContextUpdater()
    const [presentToast] = useIonToast();
    function onWithdraw() {
        loginService.privateApiCall(apiBaseUrl + "reseller-withdraw-request").then((res) => {
            if (res.data.message) {
                presentToast({
                    message: res.data.message,
                    duration: 3000,
                    cssClass: 'custom-toast',
                    animated: true,
                    color: "success",
                    buttons: [
                        {
                            text: 'Dismiss',
                            role: 'cancel'
                        }
                    ],
                })
            }
        }).catch((err) => {
            console.error(err.response)

            if (err.response.data.message) {
                presentToast({
                    message: err.response.data.message,
                    duration: 3000,
                    cssClass: 'custom-toast',
                    animated: true,
                    color: "danger",
                    buttons: [
                        {
                            text: 'Dismiss',
                            role: 'cancel'
                        }
                    ],
                })
            }
        })
    }
    return <>
        <div id='profile-menu'>
            {/* <IonItem button={true}>
                <IonIcon slot="start" icon={person} />
                <IonText>Update Profile</IonText>
            </IonItem> */}
            {isAdmin ? <IonItem routerLink={appSubDir + "dashboard"}>
                <IonText slot="start" style={{ color: "rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.54)" }}>
                    <FontAwesomeIcon fontSize={25} icon={faDashboard} />
                </IonText>
                <IonText>My Dashboard</IonText>
            </IonItem> : null}
            <IonItem routerLink={appSubDir + "invoices"}>
                <IonIcon slot="start" icon={document} />
                <IonText>{isAdmin ? "All Orders" : "My Invoices"}</IonText>
            </IonItem>
            <IonItem button={true} onClick={() => {
                loginService.doLogOut().then(() => {
                    authModalRef.current?.dismiss()
                    setAppData({ ...appData, user: loginService.currentUser })
                })
            }}>
                <IonIcon slot="start" icon={power} />
                <IonText>Logout</IonText>
            </IonItem>
        </div>
    </>
};
export default UserMenu
