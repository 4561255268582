import { IonCard, IonCardContent, IonCardHeader, IonCol, IonItem, IonItemGroup, IonLabel, IonRow, IonText } from '@ionic/react';

import { useRef, useState } from 'react';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import { Navigation, Thumbs } from 'swiper';
import ZoomableImg from '../ZoomableImg';
import './ProductBody.scss'
import QuickViewAddToCart from './QuickViewAddToCart';
import { getPriceFactor, getProductImageUrl, getProductPrice } from '../../functions/product.functions';
import Product from '../../types/product.type';
import Tab from '../Tab';
import ProductDescription from './ProductDescription';
import ProductTabSellerInfo from './ProductTabSellerInfo';
import ProductScale from './ProductScale';
import { useAppContext } from '../../services/app-context.service';
const BDNumer = new Intl.NumberFormat('en-IN');
type Props = {
    item: Product
}
const ProductBody: React.FC<Props> = ({ item }) => {
    const [selectedskuProp, setSelectedSkuProp] = useState<number[]>([])
    const slideRef = useRef<SwiperRef>(null)
    const [thumbsSwiper, setThumbsSwiper] = useState<any>(null);
    const data = useAppContext()
    const priceFactor = getPriceFactor(data.settings)
    const slides = item.main_imgs
    const selectedskuPropStr1 = selectedskuProp.map((item, index) => (index + ":" + item)).join(";")
    const selectedskuPropStr2 = selectedskuProp.map((vi, pi) => (item.sku_props[pi].pid + ":" + item.sku_props[pi].values[vi].vid)).join(";")
    const selectedsku = item.skus.find((item) => item.props_ids === selectedskuPropStr1 || item.props_ids === selectedskuPropStr2)
    item.sku_props && item.sku_props.map((skuProps, pi) => {
        return skuProps.values.map((value, vi) => {
            if (value.imageUrl && !slides.includes(value.imageUrl)) {
                slides.push(value.imageUrl)
            }
        })
    })
    const [selectedImgUrl, setSelctedImgUrl] = useState<string>(slides[0])
    function getPriceRange() {
        if (selectedsku && parseInt(selectedsku.sale_price)) {
            return getProductPrice(selectedsku.sale_price,priceFactor)
        } else if (item && item.sku_price_scale) {
            const priceParts = item.sku_price_scale.replaceAll("￥", "").split("-")
            if (priceParts[0] === priceParts[1]) {
                return getProductPrice(priceParts[0],priceFactor)
            } else {
                return getProductPrice(priceParts[0],priceFactor) + " - " + getProductPrice(priceParts[1],priceFactor)
            }
        } else {
            return ""
        }
    }
    return <>
        <IonRow id="single-product">
            <IonCol sizeXs='12' sizeMd='6'>
                <div className='swiper-slide'>
                    <Swiper
                        ref={slideRef}
                        zoom={true}
                        thumbs={{ swiper: thumbsSwiper }}
                        navigation={true}
                        modules={[Navigation, Thumbs]}>
                        {item.main_imgs.map((url, index) => <SwiperSlide key={index}>
                            <a href={url} target='_blank'><ZoomableImg src={url} /></a>
                        </SwiperSlide>)}
                    </Swiper>
                    <Swiper
                        slidesPerView={10}
                        navigation={true}
                        modules={[Navigation, Thumbs]}
                        watchSlidesProgress
                        onSwiper={(swiper) => setThumbsSwiper(swiper)}>
                        {item.main_imgs.map((url, index) => <SwiperSlide key={index}>
                            <img src={getProductImageUrl(url, 50)} alt="" loading='lazy' />
                        </SwiperSlide>)}
                    </Swiper>
                </div>
            </IonCol>
            <IonCol sizeXs='12' sizeMd='6' className='left-colum'>
                <IonCard>
                    <IonCardContent>
                        <IonText><h5 className='title'>{item.title}</h5></IonText>
                    </IonCardContent>
                </IonCard>
                {(item.sku_price_range.sku_param && item.sku_price_range.sku_param.length > 1) && <IonCard>
                    <IonCardContent>
                        <ProductScale prices={item.sku_price_range.sku_param} />
                    </IonCardContent>
                </IonCard>}
                {item.sku_props && item.sku_props.map((skuProps, pi) => {
                    return <IonCard key={pi}>
                        <IonCardHeader><b>{skuProps.prop_name} :</b>{selectedskuProp[pi] > -1 ? item.sku_props[pi].values[selectedskuProp[pi]].name : "Select One Fron bellow To Order"}</IonCardHeader>
                        <IonCardContent>
                            {skuProps.values.map((value, vi) => {
                                return <span key={vi} className={"sku-props" + (selectedskuProp[pi] === vi ? " active" : "")} onClick={() => {
                                    if (value.imageUrl) {
                                        setSelctedImgUrl(value.imageUrl)
                                        slideRef.current?.swiper.slideTo(slides.findIndex((url) => (url === value.imageUrl)))
                                    }
                                    setSelectedSkuProp((current) => {
                                        current[pi] = vi
                                        return [...current]
                                    })
                                }}>
                                    {value.imageUrl ?
                                        <img src={getProductImageUrl(value.imageUrl, 50)} alt="" loading='lazy' /> :
                                        <>{value.name}</>}
                                </span>
                            })}
                        </IonCardContent>
                    </IonCard>
                })}
                {/* <pre>
                    {selectedImgUrl.current+"\n"}
                    {JSON.stringify(selectedsku, null, 2)}
                </pre> */}
                <IonRow>
                    <IonCol sizeXs='12' sizeMd='6'>
                        <IonCard>
                            <IonCardContent>
                                <IonText color='danger' className='price'>{getPriceRange()}</IonText>
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                    <IonCol sizeXs='12' sizeMd='6'>
                        <IonCard>
                            <IonCardContent>
                                {selectedsku && parseInt(selectedsku.stock) ? <IonText className='stock'>
                                    Only <b>{BDNumer.format(parseInt(selectedsku.stock))}</b> Item{parseInt(selectedsku.stock) > 1 ? "s" : ''} left.
                                </IonText> :
                                    (
                                        item.sku_price_range.stock > 0 ?
                                            <IonText className='stock'>
                                                Only <b>{BDNumer.format(item.sku_price_range.stock)}</b> Item{item.sku_price_range.stock > 1 ? "s" : ''} left.
                                            </IonText> :
                                            <IonText className='stock' color='danger'>Out Of Stock. </IonText>
                                    )}
                                {selectedsku && parseInt(selectedsku.sale_count) ? <IonText className='stock'>
                                    <b>{BDNumer.format(parseInt(selectedsku.sale_count))}</b> Item{parseInt(selectedsku.stock) > 1 ? "s" : ''} Sold
                                </IonText> : null}
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>
                {selectedsku ? <QuickViewAddToCart item={item} imageUrl={selectedImgUrl} selectedSku={selectedsku} /> :
                    <IonCard>
                        <IonCardHeader>
                            <h3 style={{ margin: 0 }}>Please, select options above to add items in your shopping cart.</h3>
                        </IonCardHeader>
                    </IonCard>}
                <IonCard color='warning'>
                    <IonCardHeader>
                        <h3 style={{ margin: 0 }}>{data.settings?.shipping_instruction_1}</h3>
                    </IonCardHeader>
                    <IonCardContent>
                        উপরে উল্লেখিত মুল্য শুধুমাত্র আপনার পণ্যের ক্রয়মুল্য, পণ্য আসার পর শিপিং চার্জ আলাদা যোগ হবে।
                    </IonCardContent>
                </IonCard>
                <IonCard color='warning'>
                    <IonCardContent>{data.settings?.shipping_instruction_2}</IonCardContent>
                </IonCard>
                <IonCard color='warning'>
                    <IonCardContent>{data.settings?.shipping_instruction_3}</IonCardContent>
                </IonCard>
                <IonCard color='warning'>
                    <IonCardContent>{data.settings?.shipping_instruction_4}</IonCardContent>
                </IonCard>
            </IonCol>
        </IonRow>
        <IonCard>
            <IonCardContent>
                <Tab tabItems={[
                    {
                        title: "Additional Info",
                        body: <>
                            <IonItemGroup>
                                {item.product_props.map((item: any, index: number) => {
                                    const key = Object.keys(item)[0]
                                    return <IonItem key={index}>
                                        <IonLabel slot='start'>{key}</IonLabel>
                                        <IonText>{item[key]}</IonText>
                                    </IonItem>
                                })}
                            </IonItemGroup>
                        </>
                    },
                    {
                        title: "Desctiption",
                        body: <ProductDescription item_id={item.item_id} />
                    },
                    {
                        title: "Shop Info",
                        body: <ProductTabSellerInfo member_id={item.shop_info.seller_member_id} />
                    }
                ]} />
            </IonCardContent>
        </IonCard>
    </>
};

export default ProductBody;
