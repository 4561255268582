import { IonCol, IonContent, IonRow, IonText, IonTitle, useIonViewDidEnter } from '@ionic/react';
import Header from '../components/Header/Header';
import { onScroll } from '../services/sticky-menu.service';
import Footer from '../components/Footer';

const RefundPolicy: React.FC = () => {
    useIonViewDidEnter(() => {
        const tabBar = document.querySelector('ion-tab-bar');
        if (tabBar !== null) {
            tabBar.style.display = 'none';
        }
    })
    return (
        <IonContent fullscreen scrollEvents={true} onIonScroll={onScroll}>
            <Header />
            <IonRow>
                <IonCol sizeXl='10' offsetXl='1' sizeLg='10' offsetLg='1' className='no-padding bg-content'>
                    <div style={{ padding: 20, minHeight: '80vh' }}>

                        <IonTitle><h1 className='text-center'>Refund Policy</h1></IonTitle>
                        <IonText><hr />
                            আমাদের পন্যগুলি বিভিন্ন মাধ্যম যেমন চায়না লোকাল ক্যুরিয়ার, by air এবং sea তে আসে । এছাড়াও কাস্টম এবং শুল্ক অধিদপ্তর carton থেকে পণ্য বের করে নিরীক্ষা করে থাকে। এই জন্য পণ্য ভাঙতে পারে বা নষ্ট হতে পারে। এই ক্ষেত্রে কাষ্টমারদের অভিযোগ সর্বোচ্চ গুরুত্ব সহকারে নিয়ে থাকি এবং যেকোন সমস্যা দ্রুত সমাধানের চেষ্টা করে থাকি।<hr />

                            নিম্নোক্ত শর্তসাপেক্ষে আমরা পন্য ফেরত কিংবা রিফান্ড দিয়ে থাকি।<hr />

                            যেসব ক্ষেত্রে রিফান্ড দেয়া হয়ঃ
                            <p>✅ পন্য ভাংগা বা নষ্ট পেলে পন্য হাতে পাবার ২ দিনের মধ্যে ছবি সহ ক্লেইম করতে হবে।</p>
                            <p>✅ পন্য রিসিভ করার পর সাইটে দেওয়া পন্যের সাথে মিল না থাকলে।</p>
                            <p>✅ আপনার দেওয়া সাইজ এবং কালার মিল না থাকলে। তবে উল্লেখ থাকে যে, লাইটিং এবং রেজুলেশন এর কারনে কালার ৫%-১০% তারতম্য হতে পারে যেটা কালারের পার্থক্য হিসেবে গন্য হবেনা।</p>
                            <p>✅ ইলেকট্রনিক পন্যের কোন ওয়ারেন্টি দেয়া হয় না।</p>
                            <hr /><hr />
                            যেসব ক্ষেত্রে রিটার্ণ দেয়া হয়না-

                            <p>✅ আপনার ঠিকানা ভুলের কারনে পন্য না পেলে।</p>
                            <p>✅ বাংলাদেশ warehoue এ  আসার পরে পন্য পছন্দ হয়নি ”কিংবা “আমার এখন পন্যটি দরকার নেই”এই ধরনের ক্ষেত্রে।</p>
                            <p>✅ কাস্টম এবং শুল্ক অধিদপ্তর carton থেকে পণ্য বের করে নিরীক্ষা করে থাকলে ।</p>
                            <p>✅ সাপ্লাইয়ার এর ওয়্যার হাউজ থেকে পণ্য ডেলিভারি  হয়ে গেলে আর রিটার্ণ সম্ভব নয়। আমাদের ওয়ার হাউসে থাকাকালীন সময়ে রিটার্ন করতে চাইলে পণ্য সাপ্লাইয়ারকে ফেরত পাঠানোর খরচ ও সাপ্লাইয়ার এর শিপমেন্ট খরচ পেমেন্ট করতে হবে।</p>
                            <p>✅ বাই এয়ারের পণ্য যদি 45 দিন অতিবাহিত হয়ে যায় এবং বাই শিপের পণ্য 90 দিন অতিবাহিত হয়ে যায় তাহলে শর্ত সাপেক্ষে রিফান্ড করা হবে। </p></IonText>
                    </div>
                </IonCol>
            </IonRow>
            <Footer />
        </IonContent>
    );
};

export default RefundPolicy;
