import { IonButton, IonSpinner } from '@ionic/react';
import { useState } from 'react';
import { fromLocal, fromServer } from '../../services/products/product-description.service';
import HtmlParser from '../HtmlParser';
type Props = {
    item_id: string
}
const ProductDescription: React.FC<Props> = ({ item_id }) => {
    const [response, setResponse] = useState(fromLocal(item_id));
    const [loading, setLoading] = useState(false);
    function load() {
        setLoading(true)
        fromServer(item_id)
            .then((apiResponse) => setResponse(apiResponse))
            .catch(() => setResponse(null))
            .finally(() => setLoading(false))
    }
    return <>
        {response ? <HtmlParser html={response.data.detail_html} /> : <div style={{ textAlign: "center" }}>
            <IonButton disabled={loading} onClick={load}>
                {loading ? <IonSpinner /> : <>Show Full Description</>}
            </IonButton>
        </div>}
    </>
};

export default ProductDescription;
