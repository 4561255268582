import './Pagination.scss'
import ReactPaginate from 'react-paginate';
type Props = {
    active: number
    disabled: boolean
    buttonCount: number
    onClick: (pageNo: number) => void
}
const Pagination: React.FC<Props> = ({ buttonCount, onClick, disabled, active }) => {
    const handlePageClick = (event: any) => {
        // console.log(event.selected)
        window.scrollTo({top: 0, behavior: 'smooth'});
        onClick(event.selected);
        return false
    };
    return <ReactPaginate
        className='pagination'
        breakLabel="..."
        nextLabel=">"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={buttonCount}
        previousLabel="<"
        renderOnZeroPageCount={null}
    />
};

export default Pagination;
