import { IonCol, IonContent, IonRow, useIonViewDidEnter } from '@ionic/react';
import Header from '../../components/Header/Header';
import { onScroll } from '../../services/sticky-menu.service';
import Footer from '../../components/Footer';
import { useParams } from 'react-router';
import { baseUrl } from '../../configs/app';
import { useState } from 'react';
import ImageSearchResult from '../../components/Products/ImageSearchResult';
import { trackPageView } from '../../functions/analytics.functions';

type CustomParam = {
  fileName: string
}
const SearchByImage: React.FC = () => {
  const { fileName } = useParams() as CustomParam
  const fileUrl = baseUrl + "storage/temp/" + fileName
  const [imageNotFound, setImageNotFound] = useState(false)
  useIonViewDidEnter(() => {
      trackPageView("Search By Image")
  })
  return (
    <IonContent fullscreen scrollEvents={true} onIonScroll={onScroll}>
      <Header />
      <IonRow>
        <IonCol sizeXl='10' offsetXl='1' sizeLg='10' offsetLg='1' className='ion-padding bg-content'>
          <div className='page-container'>
            <div className='text-center'>
              {imageNotFound ? <h1>The Image Not Found</h1> : <>
                <h1>Searching Products By</h1>
                <img onError={() => setImageNotFound(true)} src={fileUrl} style={{ maxWidth: 150 }} />
              </>}
            </div>
            {!imageNotFound &&<ImageSearchResult fileName={fileName}/>}
          </div>
        </IonCol>
      </IonRow>
      <Footer />
    </IonContent>
  );
};

export default SearchByImage;
