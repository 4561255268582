import { IonContent, IonSpinner, useIonToast } from '@ionic/react';
import { appSubDir } from '../../configs/app';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCarCrash, faCircleCheck, faClose } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { execute } from '../../services/bkash-due-payment-service';
const BkashDuePaymentExecution: React.FC = () => {
    const query = new URLSearchParams(window.location.search);
    const initialStatus = query.get('status')
    const paymentID = query.get('paymentID')
    const [executing, setExecuting] = useState(true)
    const [status, setStatus] = useState(initialStatus)
    function getText() {
        switch (status) {
            case "success":
                return "Payment Was Successful. Opening The Invoice...";
            case "cancel":
                return "Payment Was Cancelled. Redirecting To Home...";
            default:
                return "Payment Was Failed. Redirecting To Home...";
        }
    }
    const [text, setText] = useState(getText())
    const [presentToast] = useIonToast();
    const history = useHistory()
    function getIcon() {
        switch (status) {
            case "success":
                return faCircleCheck;
            case "cancel":
                return faClose;
            default:
                return faCarCrash;
        }
    }
    function getColor() {
        switch (status) {
            case "success":
                return 'green';
            default:
                return 'red';
        }
    }
    function waitAndGo(route: string, duration = 3000) {
        setTimeout(() => {
            history.replace(route)
        }, duration)
    }
    useEffect(() => {
        if (initialStatus === "success" && paymentID) {
            execute(paymentID).then((invoice_id) => {
                presentToast({
                    message: "Due Payment Successful.",
                    duration: 3000,
                    cssClass: 'custom-toast',
                    animated: true,
                    color: "success",
                    buttons: [
                        {
                            text: 'Dismiss',
                            role: 'cancel'
                        }
                    ],
                })
                setTimeout(() => {
                    history.replace(appSubDir + "invoices/" + invoice_id)
                }, 1000)
                waitAndGo(appSubDir + "invoices/" + invoice_id)
            }).catch((error) => {
                console.log('error', error)
                setText(error.response.data.message ? error.response.data.message : "Something Went Wrong.")
                setStatus('failed')
                // waitAndGo(appSubDir)
            }).finally(() => {
                setExecuting(false)
            })
        } else {
            setTimeout(() => {
                setExecuting(false)
            }, 100)
            waitAndGo(appSubDir)
        }
    }, [initialStatus, paymentID])
    return (
        <IonContent id='standalone-page' fullscreen>
            <div id='standalone-page'>
                <div className='box'>
                    {
                        executing ?
                            <IonSpinner color="tertiary" style={{ width: 100, height: 100, marginTop: 50, marginBottom: 50 }} /> :
                            <FontAwesomeIcon style={{ marginTop: 50, marginBottom: 50 }} color={getColor()} size='10x' icon={getIcon()} />
                    }
                    <h1 style={{ marginBottom: 50 }}>{executing ? "Don't close this window. Processing The Payment...." : text}</h1>
                </div>
            </div>
        </IonContent>
    );
};

export default BkashDuePaymentExecution;
