import { useAppContext } from '../../services/app-context.service';
import shoppingCartService from '../../services/shop/cart.service';
const BDT = new Intl.NumberFormat('en-IN');
const CartTotal: React.FC = () => {
    const { cart, user } = useAppContext()
    if (cart.itemCount < 1) {
        return null
    }
    return <div id="cart-total">
        <div>
            {cart.itemCount} Products
        </div>
        <div>
            Total : <span>{BDT.format(cart.total)} /=</span>
        </div>
        <div>
            Advance 75% : <span>{BDT.format(Math.ceil(shoppingCartService.shoppingCart.total * 0.75))} /=</span>
        </div>
    </div>
};

export default CartTotal;
