/**
 * File Name    : Service - Shopping Cart Service
 * Desctipn     : This file will provide necessary service to perform all the cart action and functions.
 * Author       : MD. Mashfiqur Rahman
 * Website      : https://mashfiqnahid.com
 * Email        : mashfiqnahid@gmail.com
 */

import { apiConfig, appSubDir, frontEndRootUrl } from '../configs/app';
import CryptoService from './crypto.services';
import loginService from './loginService';
import shoppingCartService from './shop/cart.service';

export function getKey(id: string) {
    return CryptoService.hash('bkash-payment-payload-' + id)
}
export function bkashPaymentCreate() {
    const {
        subTotal,
        items,
        s_name,
        s_phone,
        s_address,
        s_note
    } = shoppingCartService.shoppingCart
    const dataToPost = {
        subTotal,
        items: items.map((item, index) => ({
            item_id: item.item_id,
            img: item.imageUrl,
            title: item.title,
            sku_id: item.selectedSku.skuid,
            props_names: item.selectedSku.props_names,
            qty: item.quantity,
            unit_price: item.unitPrice,
            item_total: item.itemTotal
        })),
        callbackURL: frontEndRootUrl + "bkash-payment-execution",
        s_name,
        s_phone,
        s_address,
        s_note
    }
    return loginService.privateApiCall(apiConfig.bkashPayment.create, dataToPost).then((res) => {
        const key = getKey(res.data.paymentID)
        window.localStorage.setItem(key, res.data.payload)
        window.location.href = res.data.bkashURL
    })
}
export function bkashPaymentExecute(payment_id: string) {
    const key = getKey(payment_id)
    const payload = window.localStorage.getItem(key)
    if (!payload) {
        return new Promise((resolve, reject) => {
            reject({ response: { data: { message: "No Order Data Found" } } })
        })
    }
    window.localStorage.removeItem(key)
    const dataToPost = {
        payment_id,
        payload
    }
    return loginService.privateApiCall(apiConfig.bkashPayment.execute, dataToPost).then((res) => {
        window.localStorage.removeItem(key)
        return res.data.invoice_id as string
    })
}
