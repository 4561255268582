import { IonButton } from '@ionic/react';
import { useEffect, useState } from 'react';
import { appSubDir } from '../../configs/app';
import ProductsGrid from './ProductsGrid';
import Pagination from '../Pagination';
import { fetchProducts, getProducts, ApiResponse } from '../../services/products/search-products.service';
type Props = {
    keyword: string
}
const ProductGridWithPagination: React.FC<Props> = ({ keyword }) => {
    const productResponse = getProducts(keyword)
    const [items, setItems] = useState(productResponse ? productResponse.data.items : null)
    const [page, setPage] = useState(1)
    const [lastPage, setLastPage] = useState((productResponse && productResponse.data.total_count) ? Math.ceil(parseInt(productResponse.data.total_count) / productResponse.data.page_size) : null)
    const [loading, setLoadling] = useState(false)
    useEffect(() => {
        loadProducts(1)
    }, [keyword])
    function loadProducts(pageNo: number) {
        const local = getProducts(keyword, pageNo)
        if (local) {
            setState(local)
        }
        setLoadling(true)
        fetchProducts(keyword, pageNo).then((res) => {
            if (res) {
                setState(res)
            }
        }).finally(() => setLoadling(false))
    }
    function setState(res: ApiResponse) {
        setItems(res.data.items)
        if (res.data.total_count) {
            setLastPage(Math.ceil(parseInt(res.data.total_count) / res.data.page_size))
        } else {
            setLastPage(null)
        }
        setPage(res.data.page)
    }
    return <>
        <div style={{ marginTop: 30 }}>
            <ProductsGrid title='Products' products={items ? items : []} hideHeader={true} loading={loading} />
            {lastPage && lastPage > 1 && <Pagination active={page} disabled={loading} onClick={loadProducts} buttonCount={lastPage} />}
        </div>
        {!items && !loading && <div className='text-center'>
            <h1 style={{ marginTop: 100, marginBottom: 100 }}>Sorry ! Nothing Found</h1>
            <IonButton routerLink={appSubDir} fill='outline'>
                Go To Home
            </IonButton>
        </div>}
    </>
};

export default ProductGridWithPagination;
