import { IonBadge } from '@ionic/react';
import { getBadgeColor } from '../services/account/invoices.service';
import './InvoiceBody.scss';
import { Link } from 'react-router-dom';
import { getProductImageUrl, getProductUrl } from '../functions/product.functions';
import { useAppContext } from '../services/app-context.service';
import InvoiceItemEditModal from './Admin/InvoiceItemEditModal';
import { useInvoiceContext } from '../services/invoice-context.service';
import InvoiceDuePaymentBtn from './Orders/InvoiceDuePaymentBtn';

const BDT = new Intl.NumberFormat('en-IN');
const InvoiceBody: React.FC = () => {
    const { user } = useAppContext()
    const isAdmin = user.roles.includes("admin")
    const invoice = useInvoiceContext()
    if (!invoice) {
        return null
    }
    return (
        <div id="invoice">
            <h1 className='text-center'>Invoice</h1>
            <table>
                <tbody>
                    <tr>
                        <td>
                            <strong className='title'>Invoice #</strong> {invoice.invoice_id}<br />
                            <strong className='title'>Date</strong> {new Date(Date.parse(invoice.created_at)).toDateString()}
                        </td>
                        <td>
                            <strong>Shipping Information</strong> <br />
                            {invoice.s_name} <br />
                            {invoice.s_address} <br />
                            Phone: {invoice.s_phone}
                        </td>
                    </tr>
                </tbody>
            </table>
            <br />
            <table className='items'>
                <thead>
                    <tr>
                        <th colSpan={2}>Name/Description</th>
                        <th>Qty.</th>
                        <th>MRP</th>
                        <th>Amount</th>
                    </tr>
                </thead>
                <tbody>

                    {invoice.items.map((item, index) => {
                        return <tr key={index}>
                            <td className='has-img'><img src={getProductImageUrl(item.img, 50)} alt="" loading='lazy' /></td>
                            <td>
                                <Link className='print-hidden' to={getProductUrl(item.item_id)}>{item.title}</Link>
                                <span className='screen-hidden'>{item.title}</span>
                                <p>{item.props_names}</p>
                                {isAdmin && <div className='print-hidden'>
                                    <a style={{ color: "var(--ion-color-link)", textDecoration: "none" }} href={`https://detail.1688.com/offer/${item.item_id}.html`} target='_blank'>See In 1688.com</a>
                                    <InvoiceItemEditModal itemIndex={index} />
                                </div>}
                            </td>
                            <td className='text-center'>{item.qty}</td>
                            <td className='text-end'>{BDT.format(item.unit_price)}/=</td>
                            <td className='text-end'>{BDT.format(item.item_total)}/=</td>
                        </tr>
                    })}
                </tbody>
                <tfoot>
                    <tr>
                        <td rowSpan={4} colSpan={2} className='info'>
                            <div className='row'>
                                <div className='title'>Order Status</div>
                                <div className='data'>
                                    <IonBadge color={getBadgeColor(invoice.status)}>{invoice.status}</IonBadge>
                                    <span className='screen-hidden'>{invoice.status}</span>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='title'>Paid</div>
                                <div className='data'>{BDT.format(invoice.paid)}/=</div>
                            </div>
                            <div className='row'>
                                <div className='title'>Due</div>
                                <div className='data'>
                                    {BDT.format(invoice.due)}/=
                                    {invoice.due > 0 && <InvoiceDuePaymentBtn invoice_id={invoice.invoice_id} />}
                                </div>
                            </div>
                        </td>
                        <th colSpan={2} style={{ width: 230 }}>Sub Total</th>
                        <td className='text-end'>{BDT.format(invoice.sub_total)}/=</td>
                    </tr>
                    {invoice.ccc > 0 && <tr>
                        <th colSpan={2}>China Courier Cost</th>
                        <td className='text-end'>{BDT.format(invoice.ccc)}/=</td>
                    </tr>}
                    {(invoice.otw * invoice.bccpk) > 0 && <tr>
                        <th colSpan={2}>BD Courier Cost <br /> {invoice.otw} kg X {BDT.format(invoice.bccpk)}/=</th>
                        <td className='text-end'>{BDT.format(invoice.otw * invoice.bccpk)}/=</td>
                    </tr>}
                    <tr>
                        <th colSpan={2}>Grand Total</th>
                        <td className='text-end'>{BDT.format(invoice.total)}/=</td>
                    </tr>
                </tfoot>
            </table>
            <div className='print-hidden space'></div>
        </div>
    );
};

export default InvoiceBody;
