import { appSubDir, baseUrl } from "../../configs/app";
import Category from "../../types/category.type";
import CryptoService from "../crypto.services";


const localStorageKey = CryptoService.hash('categories-and-subcategories')
export function localCategories() {
    const localData = window.localStorage.getItem(localStorageKey)
    return (localData ? JSON.parse(CryptoService.decrypt(localData)) : []) as Category[]
}
export function fetchCategories() {
    const url = appSubDir + 'cat-subcats.json'
    return fetch(url).then((res) => res.json())
        .then((res:Category[]) => {
            if (res) {
                window.localStorage.setItem(localStorageKey, CryptoService.encrypt(JSON.stringify(res)))
                return res
            } else {
                return [] as Category[]
            }
        })
}
