import { IonCol, IonContent, IonRow, useIonViewDidEnter } from '@ionic/react';
import Header from '../components/Header/Header';
import { onScroll } from '../services/sticky-menu.service';
import Footer from '../components/Footer';
import './Contact.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import ReactGA from "react-ga4";
import { documentBaseTitle } from '../configs/app';
import { useAppContext } from '../services/app-context.service';
import HtmlParser from '../components/HtmlParser';

const Contact: React.FC = () => {
    const data = useAppContext()
    useIonViewDidEnter(() => {
        const tabBar = document.querySelector('ion-tab-bar');
        if (tabBar !== null) {
            tabBar.style.display = 'none';
        }
        const title = "Contact Us"
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title });
        document.getElementsByTagName("title")[0].innerHTML = title + " | " + documentBaseTitle
    })
    return (
        <IonContent fullscreen scrollEvents={true} onIonScroll={onScroll}>
            <Header />
            <IonRow>
                <IonCol sizeXl='10' offsetXl='1' sizeLg='10' offsetLg='1' className='no-padding bg-content' id="contact-page">
                    <div className="banner">
                        <img src="assets/img/contact-us-banner.webp" alt="" loading='lazy' />
                        <div className='title'>
                            <h1>Contact Us</h1>
                            <hr />
                            <p>We provide instant support. <br /> Just one email or phone call away</p>
                        </div>
                    </div>
                    <IonRow>
                        <IonCol sizeXs='12' sizeSm='12' sizeMd='6' sizeLg='6' sizeXl='6'>
                            <div className="box text-end">
                                <h1>Location</h1>
                                <hr />
                                <p>{<HtmlParser html={data.settings?data.settings.company_address.replace(",","<br>"):""}/>}</p>
                            </div>
                        </IonCol>
                        <IonCol sizeXs='12' sizeSm='12' sizeMd='6' sizeLg='6' sizeXl='6'>
                            <div className="box">
                                <h1>Contact</h1>
                                <hr />
                                <p><a href="tel:+8801709339369" title="Click To Call" ><FontAwesomeIcon icon={faPhone} /> {data.settings?.company_phone}</a></p>
                                <p><a title="Company Email (Click To Send Email)" href="mailto:infochinahubbd@gmail.com"><FontAwesomeIcon icon={faEnvelope} /> {data.settings?.company_email}</a></p>
                            </div>
                        </IonCol>
                    </IonRow>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3650.6393151234665!2d90.35312627533735!3d23.795854978639383!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c0e82bf297c7%3A0xb0adf55568302293!2s89%2C%201%20Shah%20Alibag%20Road%2C%20Dhaka%201216!5e0!3m2!1sen!2sbd!4v1700593148513!5m2!1sen!2sbd" width="100%" height="450" loading="lazy"></iframe>
                </IonCol>
            </IonRow>
            <Footer />
        </IonContent>
    );
};

export default Contact;
