import { IonCard, IonCardContent } from '@ionic/react';
import './ProductCardSkeletonLoading.scss'

const ProductCardSkeletonLoading: React.FC = () => {
    return <IonCard  className='skeleton-product'>
        <IonCardContent>
            <div className='img'></div>
            <div className='title'></div>
            <div className='info'>
                <div className='price'></div>
                <div className='sold'></div>
            </div>
        </IonCardContent>
    </IonCard>
};

export default ProductCardSkeletonLoading;
