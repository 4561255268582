import { ToastOptions } from "@ionic/react";
import { HookOverlayOptions } from "@ionic/react/dist/types/hooks/HookOverlayOptions";
import { AxiosError } from "axios";

export const errorHandler = (err: AxiosError, presentToast: (options: ToastOptions & HookOverlayOptions) => void) => {
    const messages = [];
    const errRes = err.response?.data as any
    if (errRes && errRes.message) {
        messages.push(errRes && errRes.message)
    } else {
        messages.push("Something Went Wrong")
    }
    presentToast({
        message: messages.join(","),
        duration: 3000,
        cssClass: 'custom-toast',
        animated: true,
        color: "danger",
        buttons: [
            {
                text: 'Dismiss',
                role: 'cancel'
            }
        ],
    })
}