import { IonCol, IonContent, IonRow, IonText, IonTitle, useIonViewDidEnter } from '@ionic/react';
import Header from '../components/Header/Header';
import { onScroll } from '../services/sticky-menu.service';
import Footer from '../components/Footer';

const HowToBuy: React.FC = () => {
    useIonViewDidEnter(() => {
        const tabBar = document.querySelector('ion-tab-bar');
        if (tabBar !== null) {
            tabBar.style.display = 'none';
        }
    })
    return (
        <IonContent fullscreen scrollEvents={true} onIonScroll={onScroll}>
            <Header />
            <IonRow>
                <IonCol sizeXl='10' offsetXl='1' sizeLg='10' offsetLg='1' className='no-padding bg-content'>
                    <div style={{ padding: 20, minHeight: '80vh' }}>

                        <IonTitle><h1 className='text-center'>How To Purchase / Buy</h1></IonTitle>
                        <IonText><hr />
                            <p>✅ আপনি যে পন্য কিনতে চান তার ছবি গুগল/আলিএক্সপ্রেস/দারাজ বা যেকোন সাইট থেকে কালেক্ট করে মোবাইল/কম্পিউটারে সেভ রাখুন।</p>

                            <p>✅ এবার আমাদের সাইট www.chinahubbd.com সাইট এ যান।</p>

                            <p>✅ সার্চ বারে ক্যামেরার আইকনে ক্লিক করে আপনার পছন্দের ছবিটি সিলেক্ট করে এন্টার বাটন চাপুন।</p>

                            <p>✅ অথবা আপনি যে পন্য কিনতে চান তার নাম/মডেল সার্চ বারে লিখে এন্টার দিন অথবা সার্চ চিহ্নে ক্লিক করুন</p>

                            <p>✅ দেখবেন আপনার দেয়া ছবি/লেখার সাথে ম্যাচ করে বিভিন্ন দামের অনেক পন্য দেখা যাচ্ছে।</p>

                            <p>✅ আপনার বাজেট এর সাথে ম্যাচ করে এমন পন্যের উপর ক্লিক করুন এবং পন্যের ডিটেইলস ও সেলার রেটিং দেখুন।</p>

                            <p>✅ যদি পন্যটি আপনার পছন্দ হয় তাহলে  ADD TO CART/BUY NOW ক্লিক করুন (মনে রাখবেন ন্যুনতম ৩ পিসের এবং ১৫০০টাকার নিচে অর্ডার করলে ADD TO CART/BUY NOW বাটন কাজ করবে না। তাই মিনিমাম ৩ পিস/ এবং ১৫০০ টাকা সেট করে অর্ডার করতে হবে।</p>

                            <p>✅ ADD TO CART/BUY NOW করার পর আপনি পন্যের মুল্য ও চায়না থেকে বাংলাদেশের শিপিং ও কাষ্টমস চার্জ আলাদাভাবে দেখতে পারবেন।</p>

                            <p>✅ চায়না থেকে বাংলাদেশের শিপিং ও কাষ্টমস চার্জ এটা সাইটে উল্লেখিত ওজন এর উপর হিসাব হয়। তবে পন্য আসার পর প্রকৃত ওজন হিসেবে শিপিং চার্জ পুনঃনির্ধারিত হবে।</p>

                            <p>✅ আপনি পন্যটির অর্ডার কনফার্ম করতে চাইলে এবার CHECK OUT বাটন প্রেস করে পেমেন্ট অপশনে যান।</p>

                            <p>✅ লক্ষ্য করুন আপনার টোটাল পণ্য মুল্যের 75% টাকা পেমেন্ট অপশনে দেখাচ্ছে। এই 25% পেমেন্ট করেই আপনি অর্ডার করতে পারবেন। বাকি টাকা পন্য আসার পর ডেলিভেরীর সময় শিপিং চার্জ সহ পেমেন্ট করবেন।</p>

                            <p>✅ আপনি পেমেন্ট অপশন থেকে যেকোন লোকাল কার্ড/বিকাশ/রকেটের মাধ্যমে পেমেন্ট করতে পারবেন।</p>

                            <p>✅ পেমেন্ট কমপ্লিট হলে সাথে সাথে আপনার মেইল এ কনফার্মেশন সহ চালান পাবেন।</p>

                            <p>✅ যদি আপনি অনলাইনে পেমেন্ট এ অভ্যস্ত না থাকেন তাহলে আমাদের প্রতিনিধির হেল্প নিন। আপনার পছন্দের পন্যের লিঙ্ক আমাদের ইনবক্স করুন। আমাদের প্রতিনিধি আপনাকে সম্পূর্ণ ভাবে সহযোগীতা করবেন।</p>
                            সকল প্রকার লেনদেন ব্যাংক, ডেবিট/ক্রেডিট কার্ড, বিকাশ/রকেট মার্চেন্ট নাম্বারে করুন। ক্যাশ ট্রানজেকশান এর ব্যাপারে নিরুৎসাহিত করা হচ্ছে
                        </IonText>
                    </div>
                </IonCol>
            </IonRow>
            <Footer />
        </IonContent>
    );
};

export default HowToBuy;
