import { IonButton, IonCol, IonContent, IonFooter, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonModal, IonNote, IonRow, IonSelect, IonSelectOption, IonSpinner, IonText, IonTextarea, IonTitle, IonToolbar, useIonToast } from "@ionic/react";
import { closeCircle, pencil } from "ionicons/icons";
import { useAppContext } from "../../services/app-context.service";
import Invoice, { InvoiceUpdateFormData } from "../../types/shop/invoice.type";
import { useRef } from "react";
import { Formik, FormikHelpers } from "formik";
import { update } from "../../services/admin-order.service";
import { AxiosError } from "axios";
import { errorHandler } from "../../functions/error-handler.functions";
import { orderStatusList } from "../../configs/app";
import { useInvoiceContext, useInvoiceContextUpdater } from "../../services/invoice-context.service";


export let invoiceEditModalRef: React.RefObject<HTMLIonModalElement>
const Body: React.FC = () => {
    const invoice = useInvoiceContext()
    const setInvoice = useInvoiceContextUpdater()
    const [presentToast] = useIonToast();
    function validator(values: InvoiceUpdateFormData) {
        const errors = {} as InvoiceUpdateFormData;
        if (!values.s_name) {
            errors.s_name = 'Required';
        } else if (
            values.s_name.includes("@")
        ) {
            errors.s_name = 'Invalid Name address';
        }
        if (!values.s_address) {
            errors.s_name = 'Required';
        }

        if (!values.s_phone) {
            errors.s_phone = 'Required';
        } else if (
            values.s_phone.length != 11
        ) {
            errors.s_phone = '11 Digit Required';
        } else if (
            !values.s_phone.match(/^(01){1}[3-9]{1}\d{8}/g)
        ) {
            errors.s_phone = 'Invalid Phone Number';
        }

        if (!values.s_address) {
            errors.s_address = 'Required';
        }
        if (!values.status) {
            errors.status = 'Required';
        }

        return errors;
    }
    function onSubmit(values: InvoiceUpdateFormData, formikHelpers: FormikHelpers<InvoiceUpdateFormData>) {
        if (!invoice) {
            return
        }
        if (!invoice.id) {
            presentToast({
                message: "Invoice Id Missing",
                duration: 3000,
                cssClass: 'custom-toast',
                animated: true,
                color: "danger",
                buttons: [
                    {
                        text: 'Dismiss',
                        role: 'cancel'
                    }
                ],
            })
            return
        }
        update(invoice.id, values).then((res) => {
            presentToast({
                message: res.data.message ? res.data.message : "Update Successful",
                duration: 3000,
                cssClass: 'custom-toast',
                animated: true,
                color: "success",
                buttons: [
                    {
                        text: 'Dismiss',
                        role: 'cancel'
                    }
                ],
            })
            invoiceEditModalRef.current?.dismiss()
            const {
                status,
                abo_id,
                abt_id,
                s_name,
                s_phone,
                s_address,
                s_note,
                ccc,
                otw,
                bccpk,
                paid,
                due,
                total
            } = res.data
            setInvoice({
                ...invoice,
                status,
                abo_id,
                abt_id,
                s_name,
                s_phone,
                s_address,
                s_note,
                ccc,
                otw,
                bccpk,
                paid,
                due,
                total
            })
        }).catch((err: AxiosError) => {
            errorHandler(err, presentToast)
        }).finally(() => {
            formikHelpers.setSubmitting(false);
        })
    }
    if (!invoice) {
        return null
    }
    return <Formik
        initialValues={{
            status: invoice.status,
            abo_id: invoice.abo_id,
            abt_id: invoice.abt_id,
            mb_id: invoice.mb_id,
            s_name: invoice.s_name,
            s_phone: invoice.s_phone,
            s_address: invoice.s_address,
            s_note: invoice.s_note,
            ccc: invoice.ccc,
            otw: invoice.otw,
            bccpk: invoice.bccpk,
            paid: invoice.paid,
        } as InvoiceUpdateFormData}
        validate={validator}
        onSubmit={onSubmit}>
        {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
            /* and other goodies */
        }) => (
            <form onSubmit={handleSubmit} style={{ marginTop: 10 }}>
                <section>
                    <IonText><h6 style={{ textAlign: "center" }}>Order Details</h6></IonText>

                    <IonItem className={errors.status && touched.status ? 'ion-invalid' : undefined}>
                        <IonLabel>Status</IonLabel>
                        <IonSelect name='status'
                            placeholder="Select Status"
                            onIonChange={handleChange}
                            onBlurCapture={handleBlur}
                            value={values.status} interface="action-sheet">
                            {orderStatusList.map((item, index) => <IonSelectOption key={index} value={item}>{item}</IonSelectOption>)}
                        </IonSelect>
                        {errors.status && touched.status && <IonNote slot='error'>{errors.status}</IonNote>}
                    </IonItem>
                </section>
                <section>
                    <IonText><h6 style={{ textAlign: "center" }}>Courier Cost</h6></IonText>
                    <IonRow>
                        <IonCol>
                            <IonItem className={errors.ccc && touched.ccc ? 'ion-invalid' : undefined}>
                                <IonLabel position="floating">China Courier Cost</IonLabel>
                                <IonInput name='ccc' type="number"
                                    onIonChange={handleChange}
                                    onBlurCapture={handleBlur}
                                    value={values.ccc} />
                                {errors.ccc && touched.ccc && <IonNote slot='error'>{errors.ccc}</IonNote>}
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonItem className={errors.otw && touched.otw ? 'ion-invalid' : undefined}>
                                <IonLabel position="floating">Total Weight (KG)</IonLabel>
                                <IonInput name='otw' type="number" step="0.25"
                                    onIonChange={handleChange}
                                    onBlurCapture={handleBlur}
                                    value={values.otw} />
                                {errors.otw && touched.otw && <IonNote slot='error'>{errors.otw}</IonNote>}
                            </IonItem>
                        </IonCol>
                        <IonCol>
                            <IonItem className={errors.bccpk && touched.ccc ? 'ion-invalid' : undefined}>
                                <IonLabel position="floating">Per KG Cost</IonLabel>
                                <IonInput name='bccpk' type="number"
                                    onIonChange={handleChange}
                                    onBlurCapture={handleBlur}
                                    value={values.bccpk} />
                                {errors.bccpk && touched.bccpk && <IonNote slot='error'>{errors.bccpk}</IonNote>}
                            </IonItem>
                        </IonCol>
                    </IonRow>

                </section>
                <section>
                    <IonText><h6 style={{ textAlign: "center" }}>Payment Details</h6></IonText>
                    <IonRow>
                        <IonCol>
                            <IonItem className={errors.paid && touched.paid ? 'ion-invalid' : undefined}>
                                <IonLabel position="floating">Total Paid</IonLabel>
                                <IonInput name='paid' type="number"
                                    onIonChange={handleChange}
                                    onBlurCapture={handleBlur}
                                    value={values.paid} />
                                {errors.paid && touched.paid && <IonNote slot='error'>{errors.paid}</IonNote>}
                            </IonItem>
                        </IonCol>
                    </IonRow>

                </section>
                <section>
                    <IonText><h6 style={{ textAlign: "center" }}>1688.com Order Information</h6></IonText>
                    <IonRow>
                        <IonCol>
                            <IonItem className={errors.abo_id && touched.abo_id ? 'ion-invalid' : undefined}>
                                <IonLabel position="floating">Order ID</IonLabel>
                                <IonInput name='abo_id'
                                    onIonChange={handleChange}
                                    onBlurCapture={handleBlur}
                                    value={values.abo_id} />
                                {errors.abo_id && touched.abo_id && <IonNote slot='error'>{errors.abo_id}</IonNote>}
                            </IonItem>
                        </IonCol>
                        <IonCol>
                            <IonItem className={errors.abt_id && touched.abt_id ? 'ion-invalid' : undefined}>
                                <IonLabel position="floating">Tracking ID</IonLabel>
                                <IonInput name='abt_id'
                                    onIonChange={handleChange}
                                    onBlurCapture={handleBlur}
                                    value={values.abt_id} />
                                {errors.abt_id && touched.abt_id && <IonNote slot='error'>{errors.abt_id}</IonNote>}
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonItem className={errors.mb_id && touched.mb_id ? 'ion-invalid' : undefined}>
                                <IonLabel position="floating">Master Box ID</IonLabel>
                                <IonInput name='mb_id'
                                    onIonChange={handleChange}
                                    onBlurCapture={handleBlur}
                                    value={values.mb_id} />
                                {errors.abo_id && touched.mb_id && <IonNote slot='error'>{errors.mb_id}</IonNote>}
                            </IonItem>
                        </IonCol>
                    </IonRow>

                </section>
                <section>
                    <IonText><h6 style={{ textAlign: "center" }}>Shipping Information</h6></IonText>
                    <IonRow>
                        <IonCol>
                            <IonItem className={errors.s_name && touched.s_name ? 'ion-invalid' : undefined}>
                                <IonLabel position="floating">Name</IonLabel>
                                <IonInput name='s_name'
                                    onIonChange={handleChange}
                                    onBlurCapture={handleBlur}
                                    value={values.s_name} />
                                {errors.s_name && touched.s_name && <IonNote slot='error'>{errors.s_name}</IonNote>}
                            </IonItem>
                        </IonCol>
                        <IonCol>
                            <IonItem className={errors.s_phone && touched.s_phone ? 'ion-invalid' : undefined}>
                                <IonLabel position="floating">Phone</IonLabel>
                                <IonInput name='s_phone' clearInput={true}
                                    onIonChange={handleChange}
                                    onBlurCapture={handleBlur}
                                    value={values.s_phone} />
                                {errors.s_phone && touched.s_phone && <IonNote slot='error'>{errors.s_phone}</IonNote>}
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonItem className={errors.s_address && touched.s_address ? 'ion-invalid' : undefined}>
                        <IonLabel position="floating">Address</IonLabel>
                        <IonTextarea name='s_address'
                            onIonChange={handleChange}
                            onBlurCapture={handleBlur}
                            value={values.s_address} />
                        {errors.s_address && touched.s_address && <IonNote slot='error'>{errors.s_address}</IonNote>}
                    </IonItem>
                    <IonItem className={errors.s_note && touched.s_note ? 'ion-invalid' : undefined}>
                        <IonLabel position="floating">Note</IonLabel>
                        <IonTextarea name='s_note'
                            onIonChange={handleChange}
                            onBlurCapture={handleBlur}
                            value={values.s_note} />
                        {errors.s_note && touched.s_note && <IonNote slot='error'>{errors.s_note}</IonNote>}
                    </IonItem>
                </section>
                <IonButton disabled={isSubmitting} type='submit' expand="block" color="primary">{
                    isSubmitting ?
                        <IonSpinner /> :
                        "Update"
                }</IonButton>
            </form>
        )}
    </Formik>
};
const InvoiceEditModal: React.FC = () => {
    const { user } = useAppContext()
    const invoice = useInvoiceContext()
    invoiceEditModalRef = useRef<HTMLIonModalElement>(null)
    const isAdmin = user.roles.includes("admin")
    if (!isAdmin) {
        return null;
    }
    function onClick() {
        invoiceEditModalRef.current?.present()
    }
    if (!invoice) {
        return null
    }
    return <>
        <IonButton onClick={onClick} fill='outline'>
            <IonIcon icon={pencil} slot='start' />
            Edit
        </IonButton>
        <IonModal ref={invoiceEditModalRef}>
            <IonHeader>
                <IonToolbar color="light">
                    <IonTitle>Invoice #{invoice.invoice_id} Edit </IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent color="light">
                <Body />
            </IonContent>

            {window.screen.width <= 600 && <IonFooter><IonButton fill='clear' expand="block" onClick={() => invoiceEditModalRef.current?.dismiss()}>
                <IonIcon slot="start" icon={closeCircle} /> Close
            </IonButton></IonFooter>}

        </IonModal>
    </>
};
export default InvoiceEditModal
