import { IonButton, IonCol, IonContent, IonFooter, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonModal, IonNote, IonRow, IonSelect, IonSelectOption, IonSpinner, IonText, IonTextarea, IonTitle, IonToolbar, useIonToast } from "@ionic/react";
import { closeCircle, pencil } from "ionicons/icons";
import { useAppContext } from "../../services/app-context.service";
import Invoice, { InvoiceItem, InvoiceItemUpdateFormData } from "../../types/shop/invoice.type";
import { useRef } from "react";
import { Formik, FormikHelpers } from "formik";
import { updateItem } from "../../services/admin-order.service";
import { AxiosError } from "axios";
import { errorHandler } from "../../functions/error-handler.functions";
import { orderStatusList } from "../../configs/app";
import { useInvoiceContext, useInvoiceContextUpdater } from "../../services/invoice-context.service";
import { Link } from "react-router-dom";
import { getProductImageUrl, getProductUrl } from "../../functions/product.functions";

type BodyProps = {
    itemIndex: number
    modal:  React.RefObject<HTMLIonModalElement>
}

const Body: React.FC<BodyProps> = ({ itemIndex,modal }) => {
    const [presentToast] = useIonToast();
    const invoice = useInvoiceContext()
    const setInvoice = useInvoiceContextUpdater()
    function validator(values: InvoiceItemUpdateFormData) {
        const errors = {} as any;
        if (!values.qty) {
            errors.qty = 'Required';
        }
        if (!values.unit_price) {
            errors.unit_price = 'Required';
        }

        return errors;
    }
    function show(color:string,msg: string) {
        presentToast({
            message: msg,
            duration: 3000,
            cssClass: 'custom-toast',
            animated: true,
            color,
            buttons: [
                {
                    text: 'Dismiss',
                    role: 'cancel'
                }
            ],
        })
    }
    function onSubmit(values: InvoiceItemUpdateFormData, formikHelpers: FormikHelpers<InvoiceItemUpdateFormData>) {
        if (!item.order_id) {
            show("danger","Invoice Id Missing")
            return
        }
        updateItem(item.order_id, item.oi_id, values).then((res) => {
            show("success","Item Update Successful")
            modal.current?.dismiss()
            const {
                items,
                sub_total,
                due,
                total
            } = res.data
            if (invoice) {
                setInvoice({
                    ...invoice,
                    items,
                    sub_total,
                    due,
                    total
                })
            }
        }).catch((err: AxiosError) => {
            errorHandler(err, presentToast)
        }).finally(() => {
            formikHelpers.setSubmitting(false);
        })
    }
    if (!invoice) {
        return null
    }
    const item = invoice.items[itemIndex]
    return <Formik
        initialValues={{
            qty: item.qty,
            unit_price: item.unit_price
        } as InvoiceItemUpdateFormData}
        validate={validator}
        onSubmit={onSubmit}>
        {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
            /* and other goodies */
        }) => (
            <form onSubmit={handleSubmit} style={{ marginTop: 10 }}>
                <section>
                    <table>
                        <tr>
                            <td className='has-img'><img src={getProductImageUrl(item.img, 200)} alt="" loading='lazy' /></td>
                            <td>
                                <Link to={getProductUrl(item.item_id)}>{item.title}</Link>
                                <p>{item.props_names}</p>
                            </td>
                        </tr>
                    </table>
                    <IonRow>
                        <IonCol>
                            <IonItem className={errors.qty && touched.qty ? 'ion-invalid' : undefined}>
                                <IonLabel position="floating">Quantity</IonLabel>
                                <IonInput name='qty' type="number"
                                    onIonChange={handleChange}
                                    onBlurCapture={handleBlur}
                                    value={values.qty} />
                                {errors.qty && touched.qty && <IonNote slot='error'>{errors.qty}</IonNote>}
                            </IonItem>
                        </IonCol>
                        <IonCol>
                            <IonItem className={errors.unit_price && touched.unit_price ? 'ion-invalid' : undefined}>
                                <IonLabel position="floating">Unit Price</IonLabel>
                                <IonInput name='unit_price' type="number"
                                    onIonChange={handleChange}
                                    onBlurCapture={handleBlur}
                                    value={values.unit_price} />
                                {errors.unit_price && touched.unit_price && <IonNote slot='error'>{errors.unit_price}</IonNote>}
                            </IonItem>
                        </IonCol>
                    </IonRow>

                </section>
                <IonButton disabled={isSubmitting} type='submit' expand="block" color="primary">{
                    isSubmitting ?
                        <IonSpinner /> :
                        "Update Item"
                }</IonButton>
            </form>
        )}
    </Formik>
};
type Props = {
    itemIndex: number
}
const InvoiceItemEditModal: React.FC<Props> = (props) => {
    const { user } = useAppContext()
    const modal = useRef<HTMLIonModalElement>(null)
    const isAdmin = user.roles.includes("admin")
    function onClick(e: any) {
        e.preventDefault()
        modal.current?.present()
    }
    if (!isAdmin) {
        return null;
    }
    return <>
        <a style={{ color: "var(--ion-color-link)", textDecoration: "none", marginLeft: 20 }} href="#" onClick={onClick}>
            Edit Quantity & Unit Price
        </a>
        <IonModal ref={modal} keepContentsMounted={false}>
            <IonHeader>
                <IonToolbar color="light">
                    <IonTitle>Item #{props.itemIndex + 1} Edit </IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent color="light">
                <Body {...props} modal={modal}/>
            </IonContent>

            {window.screen.width <= 600 && <IonFooter><IonButton fill='clear' expand="block" onClick={() => modal.current?.dismiss()}>
                <IonIcon slot="start" icon={closeCircle} /> Close
            </IonButton></IonFooter>}

        </IonModal>
    </>
};
export default InvoiceItemEditModal
