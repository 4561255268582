export const appSubDir = "/"
// export const baseUrl = "http://localhost/chinahubbd/server/public/"
// export const frontEndRootUrl = "http://localhost:3031" + appSubDir
export const baseUrl = "https://test.chinahubbd.com/"
export const frontEndRootUrl = "https://chinahubbd.com" + appSubDir
export const apiBaseUrl = baseUrl + "api/"
export const storageBaseUrl = baseUrl + "storage/"
export const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITEKEY ? process.env.REACT_APP_RECAPTCHA_SITEKEY : ""
// Pricing in £
export const documentBaseTitle = document.getElementsByTagName("title")[0].innerHTML
export const minOrderAmount = 1500
export const minOrderItemCount = 3
export const company = {
    name: "China Hub BD",
    address1: "Address Line 1",
    address2: "Address Line 2",
    address3: "Address Line 3",
    address4: "Address Line 4"
}
export const orderStatusList = ["Pending", "Processing", "Purchased", "China Warehouse", "Shipped To BD", "BD Warehouse", "Shipped To Customer", "Delivered", "Complete", "Returned", "Cancelled"]

export const apiConfig = {
    baseUrl: baseUrl,
    messengerBaseUrl: apiBaseUrl + "messenger/",
    loginUrl: apiBaseUrl + "login",
    loginWithFBUrl: apiBaseUrl + "login-with-fb",
    logOut: apiBaseUrl + "logout",
    register: apiBaseUrl + "register",
    sendOtp: apiBaseUrl + "send-otp",
    userDetails: apiBaseUrl + "user-details",
    editUserDetails: apiBaseUrl + "profile/edit/details",
    placeOrder: apiBaseUrl + "order",
    bkashPayment: {
        duePayment: apiBaseUrl + "due-payment-by-bkash/",
        create: apiBaseUrl + "order-by-bkash/create",
        execute: apiBaseUrl + "order-by-bkash/execute"
    },
    slides: apiBaseUrl + "slides",
    products: apiBaseUrl + `products`,
    settings: apiBaseUrl + `settings`,
    shop: apiBaseUrl + `shops`,
    adminInvoice: {
        update: apiBaseUrl + `invoices/{id}/update`,
        updateItem: apiBaseUrl + `invoices/{id}/update-item/{index}`
    },
    privateProducts: apiBaseUrl + `private/products/random`,
    brands: apiBaseUrl + "brands",
    wishlist: apiBaseUrl + "wishlist",
    orders: apiBaseUrl + "v2/orders",
    transactions: apiBaseUrl + "transactions",
    links: apiBaseUrl + "fb-group-links",
    categoriesAndSubCategories: apiBaseUrl + "products/categories-and-subcategories",
    homeScreen: {
        public: apiBaseUrl + "app-data/home-screen",
        private: apiBaseUrl + "private-app-data/home-screen"
    }
}
export const productImageUrl = baseUrl
export const profileImageUrl = baseUrl + "images/profile/"
