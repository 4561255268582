
import ReactGA from "react-ga4";
import { documentBaseTitle } from "../configs/app";
import ReactPixel from 'react-facebook-pixel';
import { facebookPixelId, ga4MeasurementId } from "../configs/config.private";

export function trackPageView(title: string) {
    let docTitle = documentBaseTitle
    if (title !== "Home") {
        docTitle = title + " | " + documentBaseTitle
    }

    if (ga4MeasurementId)
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title });

    document.getElementsByTagName("title")[0].innerHTML = docTitle

    if (facebookPixelId)
        ReactPixel.pageView();
    
}