import { Redirect, Route } from 'react-router-dom';
import {
  IonRouterOutlet
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { appSubDir } from '../configs/app';
import Home from '../pages/Home';
import Contact from '../pages/Contact';
import About from '../pages/About';
import Category from '../pages/products/Category';
import NotFound from '../pages/NotFound';
import SubCategory from '../pages/products/SubCategory';
import Product from '../pages/products/Product';
import PaymentSuccess from '../pages/payments/PaymentSuccess';
import PaymentVerify from '../pages/payments/PaymentVerify';
import PaymentCancelled from '../pages/payments/PaymentCancelled';
import PaymentFailed from '../pages/payments/PaymentFailed';
import TermsAndConditions from '../pages/TermsAndConditions';
import RefundPolicy from '../pages/RefundPolicy';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import Search from '../pages/products/Search';
import SearchByImage from '../pages/products/SearchByImage';
import HowToBuy from '../pages/HowToBuy';
import ShippingAndDelivery from '../pages/ShippingAndDelivery';
import ProhibitedItems from '../pages/ProhibitedItems';


const PublicRoutes: React.FC = () => (
  <IonReactRouter>
      <IonRouterOutlet>
        <Route exact path={appSubDir}>
          <Home />
        </Route>
        <Route exact path={appSubDir+"about"}>
          <About />
        </Route>
        <Route exact path={appSubDir+"contact"}>
          <Contact />
        </Route>
        <Route exact path={appSubDir+"terms-and-conditions"}>
          <TermsAndConditions />
        </Route>
        <Route exact path={appSubDir+"refund-policy"}>
          <RefundPolicy />
        </Route>
        <Route exact path={appSubDir+"privacy-policy"}>
          <PrivacyPolicy />
        </Route>
        <Route exact path={appSubDir+"how-to-buy"}>
          <HowToBuy />
        </Route>
        <Route exact path={appSubDir+"shipping-and-delivery"}>
          <ShippingAndDelivery />
        </Route>
        <Route exact path={appSubDir+"prohibited-Items"}>
          <ProhibitedItems />
        </Route>
        <Route exact path={appSubDir+"search/:keyword"}>
          <Search />
        </Route>
        <Route exact path={appSubDir+"search-by-image/:fileName"}>
          <SearchByImage />
        </Route>
        <Route exact path={appSubDir+"product/abb-:ID"}>
          <Product />
        </Route>
        <Route exact path={appSubDir+"categories/:cat"}>
          <Category />
        </Route>
        <Route exact path={appSubDir+"categories/:cat/sub-categories/:subCat"}>
          <SubCategory />
        </Route>
        <Route exact path={appSubDir+"payment-verify/:valID"}>
          <PaymentVerify />
        </Route>
        <Route exact path={appSubDir+"payment/success"}>
          <PaymentSuccess />
        </Route>
        <Route exact path={appSubDir+"payment/failed"}>
          <PaymentFailed />
        </Route>
        <Route exact path={appSubDir+"payment/cancelled"}>
          <PaymentCancelled />
        </Route>
        <Route>
          <NotFound />
        </Route>
      </IonRouterOutlet>
  </IonReactRouter>
);

export default PublicRoutes;
