import { getProductRawPrice } from '../../functions/product.functions';
import { useAppContext } from '../../services/app-context.service';
import "./CartSubTotal.scss";
const BDT = new Intl.NumberFormat('en-IN');
const CartSubTotal: React.FC = () => {
    const { cart, user } = useAppContext()
    if (cart.itemCount < 1) {
        return null
    }
    function getTotalWeight() {
        let result = 0
        cart.items.map((item) => {
            if (item.delivery_info && item.delivery_info.unit_weight > 0) {
                result += item.quantity * item.delivery_info.unit_weight
            }
        })
        return result
    }
    const weight = getTotalWeight()
    return <div id="cart-total">
        <div>
            {cart.itemCount} Product{(cart.itemCount != 1 ? "s" : "")}
        </div>
        {weight > 0 && <div>
            <span>&#8773; {BDT.format(weight)} KG</span>
        </div>}
        <div>
            Sub Total : <span>{BDT.format(cart.subTotal)} /=</span>
        </div>
    </div>
};

export default CartSubTotal;
