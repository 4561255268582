import './MobileMenu.scss'
import WishlistButton from './Shop/WishlistButton';
import CartModal from './Shop/CartModal';
import AuthModal from './Auth/AuthModal';
import { useAppContext } from '../services/app-context.service';
import UserMenuModal from './Auth/UserMenuModal';
import QuickLinksModal from './Header/QuickLinksModal';
import { IonButton, IonIcon } from '@ionic/react';
import { list, listCircle, listCircleOutline } from 'ionicons/icons';
import AllCategoryModal, { allCategoryModalRef } from './Header/AllCategoryModal';
import { appSubDir } from '../configs/app';
import { Link } from 'react-router-dom';

const MobileMenu: React.FC = () => {
    const { user } = useAppContext()

    return <>
        <nav id="bottom-menu">
            <IonButton fill='clear' onClick={() => allCategoryModalRef.current?.present()}>
                <IonIcon size='large' icon={listCircleOutline} />
            </IonButton>
            <CartModal />
            <Link to={appSubDir}>
                <img src="icon-192x192.webp" width={50} height={50} alt='Brand Logo' />
            </Link>
            {user.token ? <UserMenuModal /> : <AuthModal />}
            <QuickLinksModal />
        </nav>
        <AllCategoryModal />
    </>
};

export default MobileMenu;
