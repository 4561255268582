import { IonCol, IonContent, IonRow, useIonViewDidEnter } from '@ionic/react';
import SlidesMini from '../components/Home/SlidesMini';
import Header from '../components/Header/Header';
import AllCategories from '../components/Home/AllCategories';
import './Home.scss';
import Footer from '../components/Footer';
import { onScroll } from '../services/sticky-menu.service';
import HomeProductGrid from '../components/Home/HomeProductGrid';
import { localCategories } from '../services/products/all-category.service';
import { getMultipleRandom } from '../functions/array.functions';
import Category from '../types/category.type';
import { trackPageView } from '../functions/analytics.functions';
const Home: React.FC = () => {
    const categories = getMultipleRandom(localCategories().slice(0, 10), 3) as Category[]
    useIonViewDidEnter(() => {
        trackPageView("Home")
    })
    return (
        <>
            <IonContent fullscreen scrollEvents={true} onIonScroll={onScroll}>
                <Header />
                <IonRow>
                    <IonCol sizeXl='10' offsetXl='1' sizeLg='10' offsetLg='1' className='no-padding bg-content'>
                        <IonRow className='home-slider'>
                            {window.screen.width > 600 && <IonCol sizeXl='3' sizeLg='4' sizeMd='5'>
                                <AllCategories />
                            </IonCol>}
                            <IonCol sizeXl='9' sizeLg='8' sizeMd='7'>
                                <SlidesMini />
                            </IonCol>
                        </IonRow>
                        {categories.map((item, index) => <HomeProductGrid key={index} keyword={item.title} />)}
                    </IonCol>
                </IonRow>
                <Footer />
            </IonContent>
        </>
    );
};

export default Home;
