import { IonButton, useIonToast } from "@ionic/react";
import { useAppContext } from "../../services/app-context.service";
import { useState } from "react";
import { errorHandler } from "../../functions/error-handler.functions";
import { create } from "../../services/bkash-due-payment-service";

type Props = {
    invoice_id: string
}

const InvoiceDuePaymentBtn: React.FC<Props> = ({ invoice_id }) => {
    const { user } = useAppContext()
    const isUser = user.roles.includes("user")
    const [isFormSubmitting, setIsFormSubmitting] = useState(false)
    const [presentToast] = useIonToast()
    if (!isUser) {
        return null
    }
    return <IonButton disabled={isFormSubmitting} onClick={() => {
        setIsFormSubmitting(true)
        create(invoice_id).catch((err) => errorHandler(err, presentToast)).finally(() => setIsFormSubmitting(false))
    }} color="primary" expand="block">{
            isFormSubmitting ?
                "Processing...." :
                "Pay With Bkash"
        }</IonButton>
};
export default InvoiceDuePaymentBtn
