import { apiConfig } from "../../configs/app";
import { SimpleProduct } from "../../types/product.type";
import CryptoService from "../crypto.services";
import loginService from "../loginService";
export type ApiResponse = {
    code: number
    data: {
        page: number
        page_size: number
        total_count: string|null
        items: SimpleProduct[]
    }
}
function getKey(searchNeedle: string, pageNo: number = 1, page_size: number = 20) {
    return CryptoService.hash("products-search-niddle-" + searchNeedle + "-page-" + pageNo+ "-page_size-" + page_size)
}
export function getProducts(searchNeedle: string, pageNo: number = 1, page_size: number = 20) {
    const localStorageKey = getKey(searchNeedle, pageNo,page_size)
    const localData = window.localStorage.getItem(localStorageKey)
    return (localData ? JSON.parse(CryptoService.decrypt(localData)) as ApiResponse : null)
}
export function fetchProducts(searchNeedle: string, pageNo: number = 1, page_size: number = 20) {
    const localStorageKey = getKey(searchNeedle, pageNo)
    let promise: Promise<Response>
    promise = fetch(apiConfig.products + `?keyword=${searchNeedle}&page=${pageNo}&page_size=${page_size}`)
    return promise.then((res) => res.json())
        .then((res: ApiResponse) => {
            if (res.data && res.data.items.length > 0) {
                window.localStorage.setItem(localStorageKey, CryptoService.encrypt(JSON.stringify(res)))
                return res
            } else {
                return null
            }
        })
}
