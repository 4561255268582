import React, { createContext, useContext,useEffect,useState } from 'react';
import AppData from '../types/app-data.type';
import loginService from './loginService';
import shoppingCartService from './shop/cart.service';
import { fromLocal, fromServer } from './settings.service';

export const defaultData = {
	innerWidth:window.innerWidth,
	user:loginService.currentUser,
    cart:shoppingCartService.shoppingCart
} as AppData;
const locaSettings = fromLocal()
if(locaSettings){
	defaultData.settings = locaSettings
}

const AppContext = createContext(defaultData);
const AppContextUpdater = createContext((newData:AppData)=>{});

type Props = {
    children:JSX.Element
}
export const AppContextProvider: React.FC<Props> = ({ children }) => {
	const [data,SetData] = useState(defaultData);
    const SetAppData = (newData:AppData)=>{
        SetData(newData)
    }
	useEffect(()=>{
		const onScreenResize = ()=>{
			SetData((currentData)=>{
				const innerWidth = window.innerWidth
				return {...currentData,...{innerWidth}}
			})
		}
		const onFocus = ()=>{
			if(loginService.currentUser.token != loginService.getFromLocalStorage().token){
                window.location.reload()
            }
            const localStorageCart = shoppingCartService.getLocalCartDetails()
			if(localStorageCart && shoppingCartService.shoppingCart.total != localStorageCart.total){
                window.location.reload()
            }
		}
		fromServer().then((settings)=>{
			SetData((current)=>({...current,settings}))
		})
		window.addEventListener("resize", onScreenResize);
		window.addEventListener("focus", onFocus);
		return ()=>{
			window.removeEventListener("resize", onScreenResize);
            window.removeEventListener("focus", onFocus);
		}
	},[])
	return <AppContext.Provider value={data}>
        <AppContextUpdater.Provider value={SetAppData}>
            {children}
        </AppContextUpdater.Provider>
    </AppContext.Provider>
};

export const useAppContext = () => {
	const store = useContext(AppContext);
	if (!store) {
		throw new Error('useAppContext must be used within a AppContextProvider.');
	}
	return store;
};
export const useAppContextUpdater = () => {
	return useContext(AppContextUpdater);
};
