import { useEffect, useRef, useState } from 'react';
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { appSubDir } from '../../configs/app';
import { IonButton, IonIcon, IonItem, IonLabel } from '@ionic/react';
import Category from '../../types/category.type';
import { arrowForward } from 'ionicons/icons';
import "./AllCategories.scss"
import { fetchCategories, localCategories } from '../../services/products/all-category.service';
const slideOpts = {
    autoplay: {
        pauseOnMouseEnter: true,
        delay: 4000
    },
    pagination: false,
    direction: "vertical",
    breakpoints: {
        // when window width is >= 320px
        320: {
            slidesPerView: 6,
            spaceBetween: 2
        },
        // when window width is >= 480px
        768: {
            slidesPerView: 6,
            spaceBetween: 2
        },
        1024: {
            slidesPerView: 6,
            spaceBetween: 0
        },
        1440: {
            slidesPerView: 10,
            spaceBetween: 0
        },
        2560: {
            slidesPerView: 15,
            spaceBetween: 0
        }
    }
} as any;
const AllCategories: React.FC = () => {
    const subCategoryContainer = useRef<HTMLDivElement>(null)
    const sidebarContainer = useRef<HTMLDivElement>(null)
    const [items, setItems] = useState(localCategories())
    const [item, setItem] = useState<Category|null>(null)
    useEffect(() => {
        fetchCategories().then((categories) => {
            setItems(categories)
        })
    }, [])
    function onCategoryHover(item: Category) {
        setItem(item)
        if (subCategoryContainer) {
            subCategoryContainer.current?.classList.add('show')
        }
    }
    function onSidebarMouseLeave() {
        if (subCategoryContainer) {
            subCategoryContainer.current?.classList.remove('show')
        }
    }
    return <div ref={sidebarContainer} id='category-sidebar' onMouseLeave={onSidebarMouseLeave}>
        {items.length > 0 && <Swiper {...slideOpts} modules={[Autoplay]}>
            {items.map((item, index) => <SwiperSlide key={index}>
                <IonItem onMouseEnter={() => onCategoryHover(item)} button={true}>
                    <IonLabel>{item.title}</IonLabel>
                    <IonButton slot='end' fill='clear' routerLink={appSubDir + "categories/" + encodeURIComponent(item.title)}><IonIcon icon={arrowForward} /></IonButton>
                </IonItem>
            </SwiperSlide>)}
        </Swiper>}
        <div ref={subCategoryContainer} className="sub-categories">
            {item && item.subCategories.length > 0 && <Swiper {...slideOpts} modules={[Autoplay]}>
                {item.subCategories.map((subCats, index) => <SwiperSlide key={index}>
                    <IonItem routerLink={appSubDir + "categories/" + encodeURIComponent(item.title) + "/sub-categories/" + encodeURIComponent(subCats)}>
                        <IonLabel>{subCats}</IonLabel>
                    </IonItem>
                </SwiperSlide>)}
            </Swiper>}
        </div>
    </div>
};

export default AllCategories;
