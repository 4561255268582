import { appSubDir } from "../configs/app";
import Settings from "../types/settngs.type";
import CryptoService from "./crypto.services";

const localStorageKey = CryptoService.hash('app-settings')
export function fromLocal() {
    const localData = window.localStorage.getItem(localStorageKey)
    return (localData ? JSON.parse(CryptoService.decrypt(localData)) as Settings : null)
}
export function fromServer() {
    let promise: Promise<Response>
    promise = fetch(appSubDir+'frontend.config.json')
    return promise.then((res) => {
        if (res.status == 200) {
            return res.json() as Promise<Settings>
        } else {
            throw new Error(res.statusText)
        }
    }).then((res: Settings) => {
        console.log(res)
        window.localStorage.setItem(localStorageKey, CryptoService.encrypt(JSON.stringify(res)))
        return res
    })
}
