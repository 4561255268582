import { MouseEventHandler, useEffect, useRef, useState } from 'react';
import './ZoomableImg.scss'

type Props = {
    src: string;
}

const ZoomableImg: React.FC<Props> = ({ src }) => {
    const container = useRef<HTMLDivElement>(null)
    const [backgroundPosition,SetBackgroundPosition] = useState('0% 0%')
    const handleMouseMove = (e:any) => {
        const { left, top, width, height } = e.target.getBoundingClientRect()
        const x = (e.pageX - left) / width * 100
        const y = (e.pageY - top) / height * 100
        SetBackgroundPosition(`${x}% ${y}%`)
      }
    return (
        <figure onMouseMove={handleMouseMove} className='zoomable' style={{backgroundImage:`url(${src})`,backgroundPosition}}>
            <img src={src} alt="" loading='lazy'/>
        </figure>
    );
};

export default ZoomableImg;
