import { useEffect, useState } from 'react';
import { fetchProducts, getProducts } from '../../services/products/search-products.service';
import ProductsGrid from '../Products/ProductsGrid';
import { appSubDir } from '../../configs/app';

type Props = {
    keyword: string
}
const HomeProductGrid: React.FC<Props> = ({ keyword }) => {
    const productResponse = getProducts(keyword, 1, 18)
    const [items, setItems] = useState(productResponse ? productResponse.data.items : null)
    const [loading, setLoadling] = useState(false)
    useEffect(() => {
        const local = getProducts(keyword, 1, 18)
        if (!local) {
            setLoadling(true)
            fetchProducts(keyword, 1, 18).then((res) => {
                if (res) {
                    setItems(res.data.items)
                }
            }).finally(() => setLoadling(false))
        }
    }, [keyword])
    if (!loading && !items) {
        return null
    }
    return <ProductsGrid title={keyword} products={items ? items : []} routerLink={appSubDir + "categories/" + encodeURIComponent(keyword)} loading={loading} />

};

export default HomeProductGrid;
