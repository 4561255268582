/**
 * File Name    : Service - Shopping Cart Service
 * Desctipn     : This file will provide necessary service to perform all the cart action and functions.
 * Author       : MD. Mashfiqur Rahman
 * Website      : https://mashfiqnahid.com
 * Email        : mashfiqnahid@gmail.com
 */

import { apiConfig, appSubDir, frontEndRootUrl } from '../configs/app';
import CryptoService from './crypto.services';
import loginService from './loginService';
import shoppingCartService from './shop/cart.service';

export function getKey(id: string) {
    return CryptoService.hash('bkash-due-payment-payload-' + id)
}
export function create(invoice_id: string) {
    const dataToPost = {
        invoice_id,
        callbackURL: frontEndRootUrl + "bkash-due-payment"
    }
    return loginService.privateApiCall(apiConfig.bkashPayment.duePayment + 'create', dataToPost).then((res) => {
        const key = getKey(res.data.paymentID)
        window.localStorage.setItem(key, res.data.payload)
        window.location.href = res.data.bkashURL
    })
}
export function execute(payment_id: string) {
    const key = getKey(payment_id)
    const payload = window.localStorage.getItem(key)
    if (!payload) {
        return new Promise((resolve, reject) => {
            reject({ response: { data: { message: "No Order Data Found" } } })
        })
    }
    window.localStorage.removeItem(key)
    const dataToPost = {
        payment_id,
        payload
    }
    return loginService.privateApiCall(apiConfig.bkashPayment.duePayment + 'execute', dataToPost).then((res) => {
        window.localStorage.removeItem(key)
        return res.data.invoice_id as string
    })
}
