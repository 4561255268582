import { IonButton, IonItem, IonItemGroup, IonLabel, IonSpinner, IonText } from '@ionic/react';
import { useState } from 'react';
import { fromLocal, fromServer } from '../../services/products/product-shop.service';
import HtmlParser from '../HtmlParser';
type Props = {
    member_id: string
}
const ProductTabSellerInfo: React.FC<Props> = ({ member_id }) => {
    const [shop, setShop] = useState(fromLocal(member_id));
    const [loading, setLoading] = useState(false);
    function load() {
        setLoading(true)
        fromServer(member_id)
            .then((shop) => setShop(shop))
            .catch(() => setShop(null))
            .finally(() => setLoading(false))
    }
    if (shop) {
        return <IonItemGroup>
            <IonItem>
                <IonLabel slot='start'>Company Name</IonLabel>
                <IonText>{shop.company_name}</IonText>
            </IonItem>
            <IonItem>
                <IonLabel slot='start'>Shop Name</IonLabel>
                <IonText>{shop.shop_name}</IonText>
            </IonItem>
            <IonItem>
                <IonLabel slot='start'>Location</IonLabel>
                <IonText>{shop.location_str}</IonText>
            </IonItem>
            <IonItem>
                <IonLabel slot='start'>Business Type</IonLabel>
                <IonText>{shop.biz_type}</IonText>
            </IonItem>
            <IonItem>
                <IonLabel slot='start'>Retention Rate</IonLabel>
                <IonText>{shop.retention_rate}</IonText>
            </IonItem>
            {shop.shop_ratings.length > 0 && <IonItem>
                <IonLabel><h1>Shop Ratings</h1></IonLabel>
            </IonItem>}
            {shop.shop_ratings.map((item, index) => <IonItem key={index}>
                <IonLabel slot='start'>{item.title}</IonLabel>
                <IonText>{item.score}</IonText>
            </IonItem>)}
            {shop.factory_props.length > 0 && <IonItem>
                <IonLabel><h1>Factory Properties</h1></IonLabel>
            </IonItem>}
            {shop.factory_props.map((item, index) => <IonItem key={index}>
                <IonLabel slot='start'>{item.name}</IonLabel>
                <IonText>{item.value}</IonText>
            </IonItem>)}
        </IonItemGroup>
    } else {
        return <div style={{ textAlign: "center" }}>
            <IonButton disabled={loading} onClick={load}>
                {loading ? <IonSpinner /> : <>Show Full Description</>}
            </IonButton>
        </div>
    }
};

export default ProductTabSellerInfo;
