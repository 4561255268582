import { getPriceFactor, getPriceScale, getProductRawPrice } from '../../functions/product.functions';
import { useAppContext } from '../../services/app-context.service';
import { SKUparams } from '../../types/product.type';
type Props = {
    prices: SKUparams[]
}
const ProductScale: React.FC<Props> = ({ prices }) => {
    const data = useAppContext()
    const priceFactor = getPriceFactor(data.settings)
   
    if (!prices) {
        return null
    }

    return <table style={{ width: "100%" }}>
        <tr>
            {getPriceScale(prices,priceFactor).map((item, index) => {
                if (item.to) {
                    return <th key={index}>{item.from} - {item.to}</th>
                }
                return <th key={index}>{item.from} +</th>
            })}
        </tr>
        <tr>
            {prices.map((item, index) => {
                return <th key={index}>৳ {getProductRawPrice(item.price,priceFactor)}</th>
            })}
        </tr>
    </table>
};

export default ProductScale;
