import { IonCard, IonCardContent } from '@ionic/react';
import './Tab.scss'
import { useState } from 'react';
type Props = {
    tabItems: {
        title: string
        body: JSX.Element
    }[]
}
const Tab: React.FC<Props> = ({ tabItems }) => {
    const [selectedTab, setSelectedTab] = useState(0)
    return <>
        <div className="tab">
            <ul className="bar">
                {tabItems.map((item, index) => <li key={index} className={selectedTab === index ? "active" : ""} onClick={() => setSelectedTab(index)}>{item.title}</li>)}
            </ul>
            <div className="content">
                {tabItems[selectedTab].body}
            </div>
        </div>
    </>
};

export default Tab;
