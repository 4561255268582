import { IonIcon, IonSpinner, useIonToast } from '@ionic/react';
import { camera } from 'ionicons/icons';
import { ChangeEvent, useRef, useState } from 'react';
import { apiBaseUrl, appSubDir } from '../../configs/app';
import axios from 'axios';

const formater = new Intl.NumberFormat('en-US');
const SearchBarImageUpload: React.FC = () => {
    const fileRef = useRef<HTMLInputElement>(null);
    const [uploading, setUploading] = useState(false)
    const [presentToast] = useIonToast();
    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            const fileSizeInKB = Math.ceil(file.size / 1024)
            const fileSizeRequiredInKB = 512
            if (fileSizeInKB < fileSizeRequiredInKB) {
                let data = new FormData();
                data.append('image', new Blob([file], { type: file.type }));
                setUploading(true)
                axios.post(apiBaseUrl + 'search-img', data)
                    .then((response: any) => {
                        // console.log(response.data);
                        window.location.href = appSubDir+"search-by-image/"+encodeURIComponent(response.data.fileName)
                    })
                    .catch((error: any) => {
                        console.log(error);
                    }).finally(() => setUploading(false))
            }else{
                presentToast({
                    message: `File Is Too Large. Less Than ${formater.format(fileSizeRequiredInKB)} KB Required. ${formater.format(fileSizeInKB)} KB Selected.`,
                    duration: 3000,
                    cssClass: 'custom-toast',
                    animated: true,
                    color: "danger",
                    buttons: [
                        {
                            text: 'Dismiss',
                            role: 'cancel'
                        }
                    ],
                })
            }

        }
    };
    if (uploading) {
        return <IonSpinner slot='start' />
    }

    return <>
        <IonIcon icon={camera} slot='start' style={{ cursor: "pointer" }} onClick={() => fileRef.current?.click()} />
        <input type="file" accept='image/png,image/jpeg' ref={fileRef} onChange={handleFileChange} style={{ display: "none" }} />
    </>
};

export default SearchBarImageUpload;
