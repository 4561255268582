import { IonButton, IonIcon, IonInput, IonItem, IonSpinner, IonText } from '@ionic/react';
import { useEffect, useRef, useState } from 'react';
import './SearchBar.scss'
import { SimpleProduct } from '../../types/product.type';
import ProductListItem from '../Products/ProductListItem';
import { fetchProducts, getProducts } from '../../services/products/search-products.service';
import { search } from 'ionicons/icons';
import { appSubDir } from '../../configs/app';
import SearchBarImageUpload from './SearchBarImageUpload';

const SearchBar: React.FC = () => {
    const [products, setProducts] = useState<SimpleProduct[] | null>(null)
    const [keyword, setKeyword] = useState<string | null>(null)
    const [loading, setLoading] = useState(false)
    function onChange(e: any) {
        if (e.target && e.target.value && typeof e.target.value == 'string') {
            setKeyword(e.target.value)
            setProducts(null)
        } else {
            setProducts(null)
            setKeyword(null)
        }
    }
    useEffect(() => {
        if (keyword) {
            const localRes = getProducts(keyword)
            if (localRes && localRes.data) {
                setProducts(localRes.data.items)
            } else {
                setLoading(true)
            }
            fetchProducts(keyword).then((res) => {
                if (res && res.data) {
                    setProducts(res.data.items)
                } else {
                    setProducts(null)
                }
            }).finally(() => {
                setLoading(false)
            })
        }
    }, [keyword])

    return <>
        <div id='search-bar-container'>
            <IonItem className="search-bar">
                <SearchBarImageUpload />
                <IonInput clearInput={true} debounce={500} onIonChange={onChange} placeholder='I am looking for...' />
                {keyword && !loading && products ? <IonButton fill='clear' href={appSubDir + "search/" + encodeURIComponent(keyword)} slot='end' >View All</IonButton> : <IonIcon icon={search} slot='end' />}
            </IonItem>
            <div className='result'>
                {loading && <div className='spinner'><IonSpinner /></div>}
                {!loading && products && products.map((product, index) => <ProductListItem product={product} key={index} />)}
            </div>
        </div>
    </>
};

export default SearchBar;
