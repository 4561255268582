import { IonButton, IonCol, IonContent, IonRow, IonText, useIonViewDidEnter } from '@ionic/react';
import Header from '../components/Header/Header';
import { onScroll } from '../services/sticky-menu.service';
import Footer from '../components/Footer';
import './About.scss';
import { appSubDir } from '../configs/app';

const About: React.FC = () => {
    useIonViewDidEnter(() => {
        const tabBar = document.querySelector('ion-tab-bar');
        if (tabBar !== null) {
            tabBar.style.display = 'none';
        }
    })
    return (
        <IonContent fullscreen scrollEvents={true} onIonScroll={onScroll}>
            <Header />
            <IonRow>
                <IonCol sizeXl='10' offsetXl='1' sizeLg='10' offsetLg='1' className='no-padding bg-content' id="about-page">
                    <IonRow>
                        <IonCol sizeXs='12' sizeSm='12' sizeMd='6' sizeLg='6' sizeXl='6'>
                            <img src="assets/img/about.webp" alt="" loading='lazy' />
                        </IonCol>
                        <IonCol sizeXs='12' sizeSm='12' sizeMd='6' sizeLg='6' sizeXl='6'>
                            <div className="about">
                                <h1>China Hub BD মার্কেট প্লেসে স্বাগতম</h1>
                                <hr />
                                <p>chinahubbd.com চীনা পণ্যের একটি Marketplace, যেখানে চায়না থেকে যেকোন ধরনের পন্য পাওয়া যাবে । আমাদের মার্কেটপ্লেস প্রাথমিকভাবে ছোট এবং মাঝারি আকারের উদ্যোক্তা, ব্যবসায়ী এবং ই-কমার্স ব্যবসার জন্য সরবরাহ করে। আমরা বুঝতে পারি যে পণ্য সোর্সিংয়ের একটি বড় চ্যালেঞ্জ হল চীনে নির্ভরযোগ্য উত্পাদনকারী সংস্থাগুলি সনাক্ত করা। আমাদের মার্কেটপ্লেসে, আমরা প্রক্রিয়াটিকে আরও সহজ করেছি, আপনাকে পাইকারি হারে নির্মাতাদের কাছ থেকে সরাসরি পণ্য কেনার সুযোগ দিয়েছি।</p>

                                <p>আপনি নাম বা চিত্র দ্বারা একটি নির্দিষ্ট পণ্যের জন্য অনুসন্ধান করছেন কিনা, আপনার যা প্রয়োজন তা খুঁজে পেতে আমরা এটিকে সহজ করে দিয়েছি। আপনি যখন আমাদের চায়না গুদাম থেকে কেনাকাটা করেন, তখন আপনি আপনার পণ্যগুলি আকাশপথে 19 থেকে 29 দিনের মধ্যে বা সমুদ্রপথে 48 থেকে 60 দিনের মধ্যে বাংলাদেশে পৌঁছানোর আশা করতে পারেন। আমরা এলসি (লেটার অফ ক্রেডিট) এবং কাস্টমস সম্পর্কিত সমস্ত বিষয় পরিচালনার দায়িত্ব নিই, আমদানি প্রক্রিয়াটিকে আপনার জন্য ঝামেলামুক্ত করে। পণ্য প্রাপ্তির পরে শিপিং চার্জ প্রযোজ্য, এবং আমাদের ডেডিকেটেড গ্রাহক সেবা আপনাকে সহায়তা করার জন্য এখানে রয়েছে।</p>

                                <p>আজই চায়না হাব বিডি থেকে আপনার পছন্দের পণ্য সংগ্রহ করুন এবং আপনার ব্যবসাকে সহজে এগিয়ে নিয়ে যান।</p>
                            </div>
                        </IonCol>
                    </IonRow>
                    <div className="text-center">
                        <h1>আমাদের ব্যবসা পদ্ধতি</h1>
                        <hr />
                    </div>
                    <IonRow>
                        <IonCol sizeXs='12' sizeSm='12' sizeMd='6' sizeLg='6' sizeXl='6'>
                            <p>চায়না হাব বিডি মার্কেট প্লেস হল একটি গতিশীল ই-কমার্স প্ল্যাটফর্ম যা ক্ষুদ্র ও মাঝারি উদ্যোক্তা, ব্যবসায়ী এবং ই-কমার্স ব্যবসার চাহিদা মেটাতে ডিজাইন করা হয়েছে। আমাদের মার্কেটপ্লেস পাইকারি হারে উপলব্ধ উচ্চ-মানের চীনা পণ্যগুলির একটি যত্ন সহকারে সংগৃহীত সংগ্রহ অফার করে, যা চীন থেকে পণ্যের উত্স করা আগের চেয়ে সহজ করে তোলে।</p>

                            <p><b>সুবিধাসমূহ:</b></p>
                            <ul style={{ listStyleType: "square", marginLeft: "30px" }}>
                                <li><b>সরাসরি সোর্সিং:</b> অপ্রয়োজনীয় মধ্যস্থতাকারীদের বাদ দিয়ে আমরা আপনাকে সরাসরি চীনের উত্পাদনকারী সংস্থাগুলির সাথে সংযুক্ত করি। এর মানে হল আপনি প্রতিযোগিতামূলক পাইকারি দামে পণ্যের উৎস করতে পারেন।</li>
                                <li><b>পণ্য খুজুন সহজেই:</b> আমাদের ব্যবহারকারী-বান্ধব প্ল্যাটফর্ম আপনাকে আপনার পছন্দসই পণ্যগুলি অনায়াসে খুঁজে পেতে দেয়। আপনি পণ্যের নাম দ্বারা বা এমনকি ছবি দ্বারা অনুসন্ধান করতে পারেন, সোর্সিং প্রক্রিয়াটিকে সহজ করে৷</li>
                                <li><b>গ্লোবাল শিপিং:</b> আমরা বাংলাদেশে দুটি সুবিধাজনক শিপিং বিকল্প অফার করি। আপনি এয়ার এবং সামুদ্রিক শিপিং এর মধ্যে বেছে নিতে পারেন, ডেলিভারির সময় 19 থেকে 29 দিনের মধ্যে আকাশপথে এবং 48 থেকে 60 দিন সমুদ্রপথে।</li>
                                <li><b>২৪/৭ গ্রাহক সেবা:</b> আমরা একটি মসৃণ আমদানি অভিজ্ঞতা নিশ্চিত করে এলসি এবং কাস্টমস পদ্ধতির সাথে কাজ করার ঝামেলা কাটিয়ে উঠি। আমাদের ডেডিকেটেড কাস্টমার সার্ভিস টিম সবসময় আপনাকে সাহায্য করার জন্য সংকল্পবদ্ধ</li>
                            </ul>

                        </IonCol>
                        <IonCol sizeXs='12' sizeSm='12' sizeMd='6' sizeLg='6' sizeXl='6' className='text-end'>
                            <img src="assets/img/business-overview.webp" alt="" loading='lazy' />
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol sizeXs='12' sizeSm='12' sizeMd='6' sizeLg='6' sizeXl='6'>
                            <h1>কেন আমাদের নির্বাচন করবেন</h1>
                            <hr />
                            <p>চায়না হাব বিডি মার্কেট প্লেস বেছে নেওয়ার মাধ্যমে আপনি আপনার ব্যবসাকে পরবর্তী স্তরে নিয়ে যেতে পারেন। আমরা চীন থেকে পণ্য সোর্সিংয়ের জন্য একটি সুবিধাজনক, নির্ভরযোগ্য এবং সাশ্রয়ী সমাধান প্রদান করি। আপনি একটি স্টার্টআপ বা প্রতিষ্ঠিত ব্যবসাই হোন না কেন, আমাদের প্ল্যাটফর্মটি আপনার সোর্সিং প্রক্রিয়াকে স্ট্রীমলাইন করতে এবং আপনার ব্যবসার প্রতিযোগীতা বাড়ানোর জন্য প্রয়োজনীয় সরঞ্জাম এবং সহায়তা প্রদান করে।</p>

                            <p>আজই আমাদের মার্কেটপ্লেস অন্বেষণ করুন এবং সরাসরি সোর্সিং, দক্ষ পণ্য আবিষ্কার এবং ঝামেলা-মুক্ত আন্তর্জাতিক শিপিংয়ের সুবিধাগুলি উপভোগ করুন। চায়না হাব বিডি মার্কেট প্লেস হল আপনার মানের চীনা পণ্যের প্রবেশদ্বার, যা বাণিজ্যের বিশ্বকে আপনার দোরগোড়ার কাছাকাছি নিয়ে আসছে।</p>
                        </IonCol>
                        <IonCol sizeXs='12' sizeSm='12' sizeMd='6' sizeLg='6' sizeXl='6'>
                            <h1>আমাদের কর্মপদ্ধতি</h1>
                            <hr />

                            <p>আমাদের এই  marketplace এর মাধ্যমে আপনি উৎপাদনকারী প্রতিষ্ঠান থেকে সরাসরি পণ্য ক্রয় করতে পারবেন একদম হোলসেল রেটে । আপনি পণ্যের নাম লিখে অথবা শুধু ছবি দিয়ে কাঙ্ক্ষিত পণ্য খুব সহজেই খুঁজে নিতে পারবেন । </p>

                            <p>পণ্য ক্রয় করে চায়না ওয়ারহাউসে পোঁছানোর পর থেকে 19 /২9 দিন বাই এয়ার -এ এবং 48/ 6০ দিন বাই সী তে বাংলাদেশে পৌঁছাবে। LC এবং CUSTOMS সংক্রান্ত সমস্ত ঝামেলা সামলানর দায়িত্ব আমাদের । শিপিং চার্জ পণ্য হাতে পাবার পরে প্রযোজ্য । আপনাদের সুবিধার জন্য রয়েছে আমাদের কাস্টমার সার্ভিস । আজই আপনার পছন্দের প্রোডাক্ট সোর্স করুন China hub bd থেকে  এবং আপনার ব্যবসাকে এগিয়ে নিন কয়েক ধাপ । </p>
                        </IonCol>
                    </IonRow>
                    <div className="text-center">
                        <IonButton routerLink={appSubDir + 'contact'}><IonText>Contact Us Today</IonText></IonButton>
                    </div>
                </IonCol>
            </IonRow>
            <Footer />
        </IonContent>
    );
};

export default About;
