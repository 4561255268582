import { IonBadge, IonButton, IonCol, IonContent, IonFooter, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonModal, IonNote, IonRow, IonSelect, IonSelectOption, IonSpinner, IonText, IonTextarea, IonTitle, IonToolbar, useIonToast } from "@ionic/react";
import { closeCircle, filter } from "ionicons/icons";
import { useAppContext } from "../../services/app-context.service";
import { InvoiceFilterFormData } from "../../types/shop/invoice.type";
import { useRef } from "react";
import { Formik, FormikHelpers } from "formik";
import { orderStatusList } from "../../configs/app";

type Props = {
    filter: InvoiceFilterFormData
    onChange: (invoice: InvoiceFilterFormData) => void
}

export let invoiceFilterModal: React.RefObject<HTMLIonModalElement>
const Body: React.FC<Props> = ({ filter, onChange }) => {
    const { user } = useAppContext()
    const isAdmin = user.roles.includes('admin')
    function validator(values: InvoiceFilterFormData) {
        const errors = {} as InvoiceFilterFormData;

        return errors;
    }
    function onReset() {
        invoiceFilterModal.current?.dismiss()
        onChange({ page: 1 })
    }
    function onSubmit(values: InvoiceFilterFormData, formikHelpers: FormikHelpers<InvoiceFilterFormData>) {
        invoiceFilterModal.current?.dismiss()
        onChange({ ...values, page: 1 })
    }
    return <Formik
        initialValues={filter}
        validate={validator}
        onSubmit={onSubmit}>
        {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
            /* and other goodies */
        }) => (
            <form onSubmit={handleSubmit} style={{ marginTop: 10 }}>
                <IonRow>
                    <IonCol>
                        <IonItem className={errors.invoice_id && touched.invoice_id ? 'ion-invalid' : undefined}>
                            <IonLabel position="floating">{isAdmin ? "Order" : "Invoice"} ID</IonLabel>
                            <IonInput name='invoice_id' clearInput={true}
                                onIonChange={handleChange}
                                onBlurCapture={handleBlur}
                                value={values.invoice_id} />
                            {errors.invoice_id && touched.invoice_id && <IonNote slot='error'>{errors.invoice_id}</IonNote>}
                        </IonItem>
                    </IonCol>
                    <IonCol>
                        <IonItem className={errors.s_phone && touched.s_phone ? 'ion-invalid' : undefined}>
                            <IonLabel position="floating">Shipping Phone</IonLabel>
                            <IonInput name='s_phone' clearInput={true}
                                onIonChange={handleChange}
                                onBlurCapture={handleBlur}
                                value={values.s_phone} />
                            {errors.s_phone && touched.s_phone && <IonNote slot='error'>{errors.s_phone}</IonNote>}
                        </IonItem>
                    </IonCol>
                </IonRow>
                <IonItem className={errors.status && touched.status ? 'ion-invalid' : undefined}>
                    <IonLabel>Status</IonLabel>
                    <IonSelect slot="end" name='status'
                        placeholder="Select Status"
                        onIonChange={handleChange}
                        onBlurCapture={handleBlur}
                        value={values.status} interface="action-sheet">
                        {orderStatusList.map((item, index) => <IonSelectOption key={index} value={item}>{item}</IonSelectOption>)}
                    </IonSelect>
                    {errors.status && touched.status && <IonNote slot='error'>{errors.status}</IonNote>}
                </IonItem>
                {isAdmin && <>
                    <IonRow>
                        <IonCol>
                            <IonItem className={errors.abo_id && touched.abo_id ? 'ion-invalid' : undefined}>
                                <IonLabel position="floating">1688.com Order ID</IonLabel>
                                <IonInput name='abo_id'
                                    onIonChange={handleChange}
                                    onBlurCapture={handleBlur}
                                    value={values.abo_id} />
                                {errors.abo_id && touched.abo_id && <IonNote slot='error'>{errors.abo_id}</IonNote>}
                            </IonItem>
                        </IonCol>
                        <IonCol>
                            <IonItem className={errors.abt_id && touched.abt_id ? 'ion-invalid' : undefined}>
                                <IonLabel position="floating">1688.com Tracking ID</IonLabel>
                                <IonInput name='abt_id'
                                    onIonChange={handleChange}
                                    onBlurCapture={handleBlur}
                                    value={values.abt_id} />
                                {errors.abt_id && touched.abt_id && <IonNote slot='error'>{errors.abt_id}</IonNote>}
                            </IonItem>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonItem className={errors.mb_id && touched.mb_id ? 'ion-invalid' : undefined}>
                                <IonLabel position="floating">Master Box ID</IonLabel>
                                <IonInput name='mb_id'
                                    onIonChange={handleChange}
                                    onBlurCapture={handleBlur}
                                    value={values.mb_id} />
                                {errors.abo_id && touched.mb_id && <IonNote slot='error'>{errors.mb_id}</IonNote>}
                            </IonItem>
                        </IonCol>
                    </IonRow></>}

                <IonRow>
                    <IonCol>
                        <IonButton disabled={isSubmitting} onClick={onReset} expand="block" color="primary" fill="outline">Reset</IonButton>
                    </IonCol>
                    <IonCol>
                        <IonButton disabled={isSubmitting} type='submit' expand="block" color="primary">Apply</IonButton>
                    </IonCol>
                </IonRow>
            </form>
        )}
    </Formik>
};
const InvoiceFilter: React.FC<Props> = (props) => {
    const { user } = useAppContext()
    invoiceFilterModal = useRef<HTMLIonModalElement>(null)
    const isAdmin = user.roles.includes("admin")
    function onClick() {
        invoiceFilterModal.current?.present()
    }
    function getActiveFilterCount() {
        let count = -1
        const { filter } = props
        Object.keys(filter).map((key, index) => {
            if (Object.values(filter)[index]) {
                count++
            }
        })
        return count > 0 ? <IonBadge color='info' style={{ marginLeft: 5 }}>{count}</IonBadge> : null
    }
    return <>
        <IonButton onClick={onClick} fill='outline'>
            <IonIcon icon={filter} slot='start' />
            <IonText>Filter</IonText>
            {getActiveFilterCount()}
        </IonButton>
        <IonModal ref={invoiceFilterModal}>
            <IonHeader>
                <IonToolbar color="light">
                    <IonTitle>{isAdmin ? "Order" : "Invoice"} Filter </IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent color="light">
                <Body {...props} />
            </IonContent>

            {window.screen.width <= 600 && <IonFooter><IonButton fill='clear' expand="block" onClick={() => invoiceFilterModal.current?.dismiss()}>
                <IonIcon slot="start" icon={closeCircle} /> Close
            </IonButton></IonFooter>}

        </IonModal>
    </>
};
export default InvoiceFilter
