/**
 * File Name    : Service - Shopping Cart Service
 * Desctipn     : This file will provide necessary service to perform all the cart action and functions.
 * Author       : MD. Mashfiqur Rahman
 * Website      : https://mashfiqnahid.com
 * Email        : mashfiqnahid@gmail.com
 */

import { apiConfig } from '../configs/app';
import { InvoiceItemUpdateFormData, InvoiceUpdateFormData } from '../types/shop/invoice.type';
import loginService from './loginService';

export function update(id:number,formData: InvoiceUpdateFormData) {
    return loginService.privateApiCall(apiConfig.adminInvoice.update.replace("{id}", id.toString()), formData)
}
export function updateItem(order_id:number,oi_id:number,formData: InvoiceItemUpdateFormData) {
    return loginService.privateApiCall(apiConfig.adminInvoice.updateItem.replace("{id}", order_id.toString()).replace("{index}", oi_id.toString()), formData)
}
