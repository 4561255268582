import { appSubDir } from "../configs/app"
import { PriceScale } from "../types/product.type";
import Settings from "../types/settngs.type";
import { insertAtIndexFronEnd } from "./string.functions"

export function getProductUrl(id: string) {
    return appSubDir + "product/abb-" + id
}
const BDT = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'BDT'
});
const BDNumber = new Intl.NumberFormat('en-IN');
export function getProductPrice(price: string, priceFactor: number) {
    if (!price) {
        return null;
    }
    return BDT.format(Math.ceil(parseFloat(price) * priceFactor))
}
export function getProductRawPrice(price: string, priceFactor: number) {
    if (!price) {
        return null;
    }
    return BDNumber.format(Math.ceil(parseFloat(price) * priceFactor))
}
export function getProductPriceInNum(price: string, priceFactor: number) {
    if (!price && !parseFloat(price)) {
        return 0;
    }
    return Math.ceil(parseFloat(price) * priceFactor)
}
export function getProductImageUrl(url: string, size: number) {
    return insertAtIndexFronEnd(url, size + "x" + size + ".", 3)
}
export function getPriceFactor(settings:Settings|undefined){
    if(settings===undefined){
        return 1
    }
    return settings.bdt_per_rmb * settings.profit_per_sale
}

export function getPriceScale(skuRangePrices: {
    beginAmount: string
    price: string
}[],priceFactor:number) {
    if (!skuRangePrices) {
        return []
    }
    return skuRangePrices.map((item, index) => {
        return {
            from: parseInt(item.beginAmount),
            to: (skuRangePrices[index + 1]) ? parseInt(skuRangePrices[index + 1].beginAmount) - 1 : null,
            price: getProductPriceInNum(item.price,priceFactor)
        } as PriceScale
    })

}