import { IonIcon, IonItem, IonLabel, IonNote, IonText } from "@ionic/react";
import { useAppContext } from "../../services/app-context.service";
import { flower, person, phoneLandscape } from "ionicons/icons";
import "./UserProfile.scss";

const UserProfile: React.FC = () => {
    const { user } = useAppContext()
    return <>
        <div id='profile'>
            <div className="info">
                <IonItem >
                    <div slot="start" className="label">
                        <IonIcon icon={person} />
                        <IonLabel>Name</IonLabel>
                    </div>
                    <IonText>{user.name}</IonText>
                </IonItem>
                <IonItem >
                    <div slot="start" className="label">
                        <IonIcon icon={phoneLandscape} />
                        <IonLabel>Phone</IonLabel>
                    </div>
                    <IonText>{user.phone}</IonText>
                </IonItem>
                <IonItem >
                    <IonNote color="danger">Make sure you use "{user.phone}". If you don't, then update the phone number. Otherwise You may lose your account.For your account security you will not be able to edit your password from this panel. To Reset your password you will need to reset password after logging out.</IonNote>
                </IonItem>
            </div>
        </div>
    </>
};
export default UserProfile
