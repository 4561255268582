import React, { createContext, useContext,useState } from 'react';
import Invoice from '../types/shop/invoice.type';

export const defaultData = null as Invoice | null;

const InvoiceCContext = createContext(defaultData);
const InvoiceContextUpdater = createContext((newData:Invoice | null)=>{});

type Props = {
    children:JSX.Element
}
export const InvoiceContextProvider: React.FC<Props> = ({ children }) => {
	const [data,SetData] = useState(defaultData);
    const SetAppData = (newData:Invoice | null)=>{
        SetData(newData)
    }
	return <InvoiceCContext.Provider value={data}>
        <InvoiceContextUpdater.Provider value={SetAppData}>
            {children}
        </InvoiceContextUpdater.Provider>
    </InvoiceCContext.Provider>
};

export const useInvoiceContext = () => {
	return useContext(InvoiceCContext);
};
export const useInvoiceContextUpdater = () => {
	return useContext(InvoiceContextUpdater);
};
