import { IonCol, IonContent, IonRow, useIonViewDidEnter } from '@ionic/react';
import Header from '../../components/Header/Header';
import { onScroll } from '../../services/sticky-menu.service';
import Footer from '../../components/Footer';
import { useParams } from 'react-router';
import ProductGridWithPagination from '../../components/Products/ProductGridWithPagination';
import { trackPageView } from '../../functions/analytics.functions';

type CustomParam = {
  keyword: string
}
const Search: React.FC = () => {
  const { keyword } = useParams() as CustomParam
  useIonViewDidEnter(() => {
      trackPageView(keyword + " | Search")
  })
  return (
    <IonContent fullscreen scrollEvents={true} onIonScroll={onScroll}>
      <Header />
      <IonRow>
        <IonCol sizeXl='10' offsetXl='1' sizeLg='10' offsetLg='1' className='ion-padding bg-content'>
          <div className='page-container'>
            <div className='title-bar'>
              <h6>Searching For</h6>
              <h1>{keyword}</h1>
            </div>
            <ProductGridWithPagination keyword={keyword} />
          </div>
        </IonCol>
      </IonRow>
      <Footer />
    </IonContent>
  );
};

export default Search;
