import { apiConfig } from "../../configs/app";
import CryptoService from "../crypto.services";

type ApiResponse = {
    code: number
    data: {
        detail_imgs:string[]
        detail_html:string
    }
}
function getKey(productId: string) {
    return CryptoService.hash('product-description-' + productId)
}
export function fromLocal(productId: string) {
    const localStorageKey = getKey(productId)
    const localData = window.localStorage.getItem(localStorageKey)
    return (localData ? JSON.parse(CryptoService.decrypt(localData)) as ApiResponse : null)
}
export function fromServer(productId: string) {
    const localStorageKey = getKey(productId)
    let promise: Promise<Response>
    promise = fetch(apiConfig.products + '/' + productId + '/description')
    return promise.then((res) => res.json())
        .then((res: ApiResponse) => {
            if (res.code === 200) {
                window.localStorage.setItem(localStorageKey, CryptoService.encrypt(JSON.stringify(res)))
                return res
            } else {
                return null
            }
        })
}
