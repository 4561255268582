/**
 * File Name    : Service - Shopping Cart Service
 * Desctipn     : This file will provide necessary service to perform all the cart action and functions.
 * Author       : MD. Mashfiqur Rahman
 * Website      : https://mashfiqnahid.com
 * Email        : mashfiqnahid@gmail.com
 */

import { apiConfig } from '../../configs/app';
import Cart, { CartItem } from '../../types/shop/cart.type';
import CryptoService from '../crypto.services';
import loginService from '../loginService';
import ShippingAddress from '../../types/shop/shipping-address.type';
import { getPriceScale } from '../../functions/product.functions';
import { SKUparams } from '../../types/product.type';
export const defaultCart = {
    items: [] as CartItem[],
    itemCount: 0,
    subTotal: 0,
    s_name: "",
    s_phone: "",
    s_address: "",
    s_note: "",
    paid: 0,
    due: 0,
    total: 0,
    saveShippingDetails: true
} as Cart
const shoppingCartKey = CryptoService.hash('shopping-cart-key')
export class ShoppingCartService {
    debugMode = true
    shoppingCart = this.getLocalCartDetails() || defaultCart

    saveCart() {
        this.shoppingCart.subTotal = this.getSubTotal()
        this.shoppingCart.itemCount = this.getItemCount()
        this.shoppingCart.total = this.getTotal()
        window.localStorage.setItem(shoppingCartKey, CryptoService.encrypt(JSON.stringify(this.shoppingCart)))
    }

    getItemIndex(cartItem: CartItem) {
        return this.shoppingCart.items.findIndex((item) => (
            item.item_id === cartItem.item_id &&
            item.selectedSku.skuid === cartItem.selectedSku.skuid
        ))
    }
    getItemCount() {
        var result = 0
        this.shoppingCart.items.map((item, index) => {
            result += item.quantity
        })
        this.shoppingCart.itemCount = result
        return result
    }
    getSubTotal() {
        var result = 0
        this.shoppingCart.items.map((item, index) => {
            result += item.itemTotal
        })
        this.shoppingCart.subTotal = result
        return result
    }
    getTotal() {
        return this.shoppingCart.subTotal
    }
    updateSatePrice(cartItem: CartItem, price: number) {
        this.shoppingCart = this.getCartDetails()
        var itemIndex = this.getItemIndex(cartItem)
        if (itemIndex >= 0) {
            const productInCart = this.shoppingCart.items[itemIndex]
            if (price >= parseFloat(productInCart.price)) {
                productInCart.price = price.toString()
            } else {
                productInCart.price = productInCart.price
            }
            productInCart.itemTotal = productInCart.quantity * parseFloat(productInCart.price)
            this.shoppingCart.items[itemIndex] = productInCart
            this.saveCart()
            return productInCart
        }
        return false
    }
    updateQuantity(itemIndex: number, quantity: number,priceFactor:number) {
        if (quantity < 1) {
            return
        }
        const productInCart = this.shoppingCart.items[itemIndex]
        productInCart.quantity = quantity
        productInCart.itemTotal = this.getItemTotal(productInCart)
        this.shoppingCart.items[itemIndex] = productInCart
        this.applyPriceScale(productInCart.item_id, productInCart.sku_price_range.sku_param,priceFactor)
        this.saveCart()
    }
    getItemTotal(cartItem: CartItem) {
        return cartItem.quantity * cartItem.unitPrice
    }
    addToCart(cartItem: CartItem, quantity = 1,priceFactor:number, set = false) {
        const itemIndex = this.getItemIndex(cartItem)
        if (itemIndex >= 0) {
            const productInCart = this.shoppingCart.items[itemIndex]
            if (set && quantity > 0) {
                productInCart.quantity = quantity
            } else {
                productInCart.quantity += quantity
            }
            productInCart.itemTotal = this.getItemTotal(productInCart)
            this.shoppingCart.items[itemIndex] = cartItem
        } else {
            cartItem.quantity = quantity
            cartItem.itemTotal = this.getItemTotal(cartItem)
            this.shoppingCart.items.push(cartItem)
        }
        this.applyPriceScale(cartItem.item_id, cartItem.sku_price_range.sku_param,priceFactor)
        this.saveCart()
        return this.shoppingCart
    }
    updateUnitPrices(item_id: number, unitPrice: number) {
        this.shoppingCart.items.forEach((item, index) => {
            if (item.item_id === item_id) {
                item.unitPrice = unitPrice
                item.itemTotal = item.quantity * item.unitPrice
            }
        })
    }
    applyPriceScale(item_id: number, sku_param: SKUparams[],priceFactor:number) {
        if (sku_param && sku_param.length > 1) {
            const cartItemCount = this.getItemCount()
            const scales = getPriceScale(sku_param,priceFactor)
            const scale = scales.find((item) => (
                cartItemCount >= item.from && (
                    (item.to && cartItemCount <= item.to) ||
                    item.to === null
                )
            ))
            if (scale && scale.price > 0) {
                this.updateUnitPrices(item_id, scale.price)
            }
        }
    }
    removeFromCart(itemIndex: number, quantity = 1,priceFactor:number) {
        if (itemIndex >= 0 && this.shoppingCart.items[itemIndex].quantity > quantity) {
            const cartItem = this.shoppingCart.items[itemIndex]
            cartItem.quantity -= quantity
            this.applyPriceScale(cartItem.item_id, cartItem.sku_price_range.sku_param,priceFactor)
            this.saveCart()
        } else {
            this.removeItemFromCart(itemIndex,priceFactor)
        }
    }
    removeItemFromCart(itemIndex: number,priceFactor:number) {
        if (itemIndex >= 0) {
            const { item_id, sku_price_range } = this.shoppingCart.items[itemIndex]
            this.shoppingCart.items.splice(itemIndex, 1)
            this.applyPriceScale(item_id, sku_price_range.sku_param,priceFactor)
            this.saveCart()
        }
    }
    getLocalCartDetails() {
        const encryptedshoppingCartString = window.localStorage.getItem(shoppingCartKey)
        if (encryptedshoppingCartString) {
            return JSON.parse(CryptoService.decrypt(encryptedshoppingCartString)) as Cart
        }
        return false
    }
    getCartDetails() {
        const encryptedshoppingCartString = window.localStorage.getItem(shoppingCartKey)
        if (encryptedshoppingCartString) {
            this.shoppingCart = JSON.parse(CryptoService.decrypt(encryptedshoppingCartString))
        }
        return this.shoppingCart
    }
    updateShippgingAddress(shippingAddress: ShippingAddress) {
        this.shoppingCart = this.getCartDetails()
        this.shoppingCart = { ...this.shoppingCart, ...shippingAddress }
        this.saveCart()
        return this.shoppingCart
    }

    makeOrder() {
        return loginService.privateApiCall(apiConfig.placeOrder, this.shoppingCart)
    }
    clearCart() {
        const { s_name, s_phone, s_address, s_note, saveShippingDetails } = this.shoppingCart
        this.shoppingCart = defaultCart
        if (saveShippingDetails) {
            this.shoppingCart = { ...defaultCart, s_name, s_phone, s_address, s_note }
        } else {
            this.shoppingCart = { ...defaultCart }
        }
        this.saveCart()
    }

}
const shoppingCartService = new ShoppingCartService()
export default shoppingCartService
