import {
    IonButton, IonContent, IonFooter, IonHeader, IonIcon, IonItem, IonList, IonModal,
    IonText,
    IonTitle, IonToolbar
} from "@ionic/react";
import { closeCircle, list, logoFacebook, logoYoutube, phonePortrait, shapes, storefront } from "ionicons/icons";
import { useRef } from "react";
import { appSubDir, baseUrl } from "../../configs/app";
import AllCategoryModal from "./AllCategoryModal";
import { defaultAuthData } from "../../services/auth-context.service";
import { authModalRef } from "../Auth/AuthModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { useAppContext } from "../../services/app-context.service";

export let quickLinksModalRef: React.RefObject<HTMLIonModalElement>
const QuickLinksModal: React.FC = () => {
    const {user} = useAppContext()
    quickLinksModalRef = useRef<HTMLIonModalElement>(null)
    return <>
        <IonButton fill='clear' onClick={() => quickLinksModalRef.current?.present()}>
            <IonIcon size='large' icon={list} />
        </IonButton>
        <IonModal ref={quickLinksModalRef} id='cart-modal'>
            <IonHeader>
                <IonToolbar color="light">
                    <IonTitle>Quick Links</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent color="light" className='ion-padding' style={{ overflow: "auto" }}>
                <IonList>
                    <IonItem href={"https://www.facebook.com/chinahubbd1"} target="_blank">
                        <IonIcon slot="start" icon={logoFacebook} />
                        <IonText>Facebook Page</IonText>
                    </IonItem>
                    {window.screen.width <= 600 && <>
                        <AllCategoryModal />
                        <IonItem routerLink={appSubDir + "about"}>
                            <IonIcon slot="start" icon={logoYoutube} />
                            <IonText>About Us</IonText>
                        </IonItem>
                        <IonItem routerLink={appSubDir + "contact"}>
                            <IonIcon slot="start" icon={phonePortrait} />
                            <IonText>Contact</IonText>
                        </IonItem>
                    </>}
                </IonList>
            </IonContent>
            <IonFooter>
                {window.screen.width <= 600 && <IonButton fill='clear' expand="block" onClick={() => quickLinksModalRef.current?.dismiss()}>
                    <IonIcon slot="start" icon={closeCircle} /> Close
                </IonButton>}
            </IonFooter>
        </IonModal>
    </>
};
export default QuickLinksModal
