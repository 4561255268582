import {
    IonButton, IonCol, IonContent, IonFooter, IonHeader, IonInput, IonItem,
    IonNote, IonRow, IonSpinner, IonTitle, IonToolbar, useIonToast
} from '@ionic/react';


import { useRef, useState } from 'react';
import { Formik, FormikHelpers } from 'formik';
import loginService from '../../../services/loginService';
import { useAuthContext, useAuthContextUpdater } from '../../../services/auth-context.service';
import { authModalRef } from '../AuthModal';
import { AxiosError } from 'axios';
import { useAppContext, useAppContextUpdater } from '../../../services/app-context.service';
import { errorHandler } from '../../../functions/error-handler.functions';
type FormFields = {
    otp: string
}
const Otp: React.FC = () => {
    const appData = useAppContext()
    const setAppData = useAppContextUpdater()
    const authData = useAuthContext()
    const setAuthData = useAuthContextUpdater()
    const submitBtnRef = useRef<HTMLInputElement>(null)
    const [isFormSubmitting, setIsFormSubmitting] = useState(false)
    const [presentToast] = useIonToast();
    function validator(values: FormFields) {
        const errors = {} as FormFields;

        if (!values.otp) {
            errors.otp = 'Required';
        } else if (
            values.otp.length != 6
        ) {
            errors.otp = '6 Character Required';
        } else if (
            !values.otp.match(/([a-z0-9]){6}/g)
        ) {
            errors.otp = 'Invalid OTP';
        }

        return errors;
    }
    function onSubmit(values: FormFields, formikHelpers: FormikHelpers<FormFields>) {
        const { setSubmitting } = formikHelpers
        // console.log(values.otp);
        if (!authData.signUpPayload) {
            return
        }
        setSubmitting(true);
        setIsFormSubmitting(true)
        loginService.doSignUp(values.otp, authData.signUpPayload).then((response) => {
            appData.user = loginService.currentUser
            setAppData({ ...appData })
            presentToast({
                message: "Sign Up Successful",
                duration: 3000,
                cssClass: 'custom-toast',
                animated: true,
                color: "success",
                buttons: [
                    {
                        text: 'Dismiss',
                        role: 'cancel'
                    }
                ],
            })
            authModalRef.current?.dismiss()
        }).catch((err: AxiosError) => {
            errorHandler(err,presentToast)
        }).finally(() => {
            setSubmitting(false);
            setIsFormSubmitting(false)
        })

    }
    if (authData.signUpFormData && authData.signUpFormData.phone) {
        return (
            <>
                <IonContent color="light" id="checkout-otp">
                    <Formik
                        initialValues={{} as FormFields}
                        validate={validator}
                        onSubmit={onSubmit}>
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting
                            /* and other goodies */
                        }) => (
                            <form onSubmit={handleSubmit} style={{ padding: 20, maxWidth: 400,margin:"0 auto", textAlign: 'center' }}>
                                <img src="/assets/icon/otp-Icon.webp" alt="" height={200} />
                                <h1>An OTP was sent to {authData.signUpFormData?.phone}</h1>
                                <IonItem className={errors.otp && touched.otp ? 'ion-invalid' : undefined}>
                                    <IonInput name='otp' placeholder='Enter 6 Digit Otp'
                                        onIonChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.otp} />
                                    {errors.otp && touched.otp && <IonNote slot='error'>{errors.otp}</IonNote>}
                                </IonItem>
                                <input style={{ display: 'none' }} disabled={isSubmitting} type='submit' ref={submitBtnRef} />
                            </form>
                        )}
                    </Formik>
                </IonContent>
                <IonFooter>
                    {isFormSubmitting ? <div style={{ textAlign: 'center' }}>
                        <IonSpinner color='primary' />
                    </div> : <IonRow>
                        <IonCol>
                            <IonButton onClick={() => setAuthData({ ...authData, screen: "registration" })}
                                color="primary" fill="outline" expand="block">
                                Go Back
                            </IonButton>
                        </IonCol>
                        <IonCol>
                            <IonButton onClick={() => {
                                submitBtnRef.current?.click()
                            }} color="primary" expand="block">{
                                    isFormSubmitting ?
                                        "Verifing Details...." :
                                        "Sign Up"
                                }</IonButton>
                        </IonCol>
                    </IonRow>}
                </IonFooter>

            </>
        );
    } else {
        return <>

            <IonHeader>
                <IonToolbar color="light">
                    <IonTitle>OTP Verification</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent color="light" id="checkout-otp">
                <h1 style={{ textAlign: "center", marginTop: 100, marginBottom: 100 }}>Something Went Wrong</h1>
            </IonContent>
        </>

    }
};

export default Otp;
