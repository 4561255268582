import { apiConfig } from "../../configs/app";
import Product from "../../types/product.type";
import CryptoService from "../crypto.services";

type ApiResponse = {
    code: number
    data: Product
}
function getKey(productId: string){
    return CryptoService.hash('product-data-' + productId)
}
export function getLocalProduct(productId: string) {
    const localStorageKey = getKey(productId)
    const localData = window.localStorage.getItem(localStorageKey)
    return (localData ? JSON.parse(CryptoService.decrypt(localData)) as ApiResponse : null)
}
export function fetchProduct(productId: string) {
    const localStorageKey = getKey(productId)
    let promise:Promise<Response>
    promise = fetch(apiConfig.products + '/' + productId )
    return promise.then((res) => res.json())
        .then((res:ApiResponse) => {
            if (res.data.item_id) {
                window.localStorage.setItem(localStorageKey, CryptoService.encrypt(JSON.stringify(res)))
                return res
            } else {
                return null
            }
        })
}
