import { IonButton, IonContent, IonFooter, IonHeader, IonIcon, IonModal, IonTitle, IonToolbar, useIonToast } from "@ionic/react";
import { cartOutline, closeCircle } from "ionicons/icons";
import { useRef } from "react";
import CartBody from "./CartBody";
import "./CartModal.scss";
import { useAppContext } from "../../services/app-context.service";
import CheckoutBody from "./CheckoutBody";
import { authModalRef } from "../Auth/AuthModal";
import CartSubTotal from "./CartSubTotal";
import shoppingCartService from "../../services/shop/cart.service";
import { minOrderAmount, minOrderItemCount } from "../../configs/app";

export let checkoutModalRef: React.RefObject<HTMLIonModalElement>
export let cartModalRef: React.RefObject<HTMLIonModalElement>
const CartModal: React.FC = () => {
    cartModalRef = useRef<HTMLIonModalElement>(null)
    checkoutModalRef = useRef<HTMLIonModalElement>(null)
    const { user, cart } = useAppContext()
    const [presentToast] = useIonToast();
    function showErr(message: string) {
        presentToast({
            message: message,
            duration: 3000,
            cssClass: 'custom-toast',
            animated: true,
            color: "danger",
            buttons: [
                {
                    text: 'Dismiss',
                    role: 'cancel'
                }
            ],
        })
    }
    function onCheckout() {
        if (shoppingCartService.shoppingCart.total < minOrderAmount) {
            showErr("Order Total can not be less than " + minOrderAmount + " /=")
            return null
        }
        if (shoppingCartService.shoppingCart.itemCount < minOrderItemCount) {
            showErr("You need to order at least " + minOrderItemCount + " items")
            return null
        }
        cartModalRef.current?.dismiss().then(() => {
            checkoutModalRef.current?.present()
        })
    }
    return <>
        <IonButton fill='clear' id="btn-min-cart" onClick={() => cartModalRef.current?.present()}>
            <IonIcon size='large' icon={cartOutline} />
        </IonButton>
        <IonModal ref={cartModalRef} id='cart-modal'>
            <IonHeader>
                <IonToolbar color="light">
                    <IonTitle>Shopping Cart</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent color="light" className='ion-padding' style={{ overflow: "auto" }}>
                <CartBody />
            </IonContent>
            <IonFooter>
                <CartSubTotal />
                {cart.itemCount > 0 && <>
                    {user.token ?
                        <IonButton onClick={onCheckout} color="primary" expand="block">
                            অর্ডার করতে আপনার তথ্য দিন
                        </IonButton> :
                        <IonButton onClick={() => authModalRef.current?.present()} color="success" expand="block">
                            অর্ডার করতে লগ ইন করুন
                        </IonButton>}
                </>}
                {window.screen.width <= 600 && <IonButton fill='clear' expand="block" onClick={() => cartModalRef.current?.dismiss()}>
                    <IonIcon slot="start" icon={closeCircle} /> Close
                </IonButton>}
            </IonFooter>
        </IonModal>
        <IonModal ref={checkoutModalRef} id='checkout-modal'>
            <CheckoutBody />
        </IonModal>
    </>
};
export default CartModal
