import { apiBaseUrl } from "../../configs/app";
import { InvoiceFilterFormData, SimpleInvoice } from "../../types/shop/invoice.type";
import CryptoService from "../crypto.services";
import loginService from "../loginService";
type Response = {
    current_page: number
    data: SimpleInvoice[]
    first_page_url: string
    last_page_url: string
    next_page_url: string
    prev_page_url: string | null
    path: string
    from: number
    last_page: number
    per_page: number
    to: number
    total: number
}
export function getBadgeColor(status: string) {
    switch (status) {
        case "Pending":
            return 'primary'
            break;
        case "Processing":
            return 'tertiary'
            break;
        case "Purchased":
            return 'tertiary-600'
            break;
        case "China Warehouse":
            return 'info'
            break;
        case "Shipped To BD":
            return 'info-500'
            break;
        case "BD Warehouse":
            return 'info-600'
            break;
        case "Shipped To Customer":
            return 'success-200'
            break;
        case "Delivered":
            return 'success-300'
            break;
        case "Complete":
            return 'success'
            break;
        case "Cancelled":
            return 'danger'
            break;
        case "Returned":
            return 'warning'
            break;
        default:
            return 'default'
    }
}
function getKey(dataToPost: InvoiceFilterFormData) {
    return CryptoService.hash("all-invoices-" + JSON.stringify(dataToPost))
}
export function getAllInvoices(dataToPost: InvoiceFilterFormData) {
    const localStorageKey = getKey(dataToPost)
    const localData = window.localStorage.getItem(localStorageKey)
    return (localData ? JSON.parse(CryptoService.decrypt(localData)) as Response : null)
}
export function fetchAllInvoices(dataToPost: InvoiceFilterFormData) {
    const localStorageKey = getKey(dataToPost)
    return loginService.privateApiCall(apiBaseUrl + `invoices`, dataToPost)
        .then((res) => {
            window.localStorage.setItem(localStorageKey, CryptoService.encrypt(JSON.stringify(res.data)))
            return res.data as Response
        })
}
