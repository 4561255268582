import { IonAlert, IonButton, IonCard, IonCardContent, IonInput, IonText, useIonToast } from '@ionic/react';
import { useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import shoppingCartService from '../../services/shop/cart.service';
import { useAppContext, useAppContextUpdater } from '../../services/app-context.service';
import { cartModalRef } from '../Shop/CartModal';
import ProductWishlistButton from './ProductWishlistButton';
import Product, { Sku } from '../../types/product.type';
import { getPriceFactor, getProductPriceInNum } from '../../functions/product.functions';
type Props = {
    item: Product
    imageUrl: string;
    selectedSku: Sku;
    modalRef?: React.RefObject<HTMLIonModalElement>
}
const QuickViewAddToCart: React.FC<Props> = ({ item, imageUrl, selectedSku, modalRef }) => {
    const data = useAppContext()
    const [presentToast] = useIonToast();
    const SetData = useAppContextUpdater()
    const input = useRef<HTMLIonInputElement>(null)
    const confirmRef = useRef<HTMLIonAlertElement>(null)
    const [isOpen, setIsOpen] = useState(false)
    const minQuantity = parseInt(item.sku_price_range.begin_num) || 1
    const priceFactor = getPriceFactor(data.settings)
    function getInputValue() {
        if (input.current && input.current.value) {
            if (typeof input.current.value == "string") {
                return parseInt(input.current.value) || 0
            } else {
                return input.current.value
            }
        }
        return 0
    }
    function onChange() {
        if (input.current && input.current.value) {
            const selectedQuantity = getInputValue()
            if (selectedQuantity < minQuantity) {
                input.current.value = minQuantity
            }
        }
    }
    function onIncreament() {
        if (input.current && input.current.value) {
            const selectedQuantity = getInputValue()
            input.current.value = selectedQuantity + 1
        }
    }
    function onDecreament() {
        if (input.current && input.current.value) {
            const selectedQuantity = getInputValue()
            if (selectedQuantity > 1) {
                input.current.value = selectedQuantity - 1
            }
        }
    }
    function show(color: string, msg: string) {
        presentToast({
            message: msg,
            duration: 3000,
            cssClass: 'custom-toast',
            animated: true,
            color,
            buttons: [
                {
                    text: 'Dismiss',
                    role: 'cancel'
                }
            ],
        })
    }
    function onAddToCart() {
        const selectedQuantity = getInputValue()
        if (data.cart.items.length > 0 && data.cart.items[0].item_id != item.item_id) {
            // confirmRef.current?.present()
            setIsOpen(true)
            return
        }
        modalRef?.current?.dismiss()
        cartModalRef.current?.present()
        if (selectedQuantity && parseFloat(selectedSku.sale_price)) {
            const unitPrice = getProductPriceInNum(selectedSku.sale_price,priceFactor)
            const cartItem = {
                ...item,
                imageUrl: imageUrl,
                selectedSku: selectedSku,
                quantity: selectedQuantity,
                unitPrice,
                itemTotal: selectedQuantity * unitPrice
            }
            shoppingCartService.addToCart(cartItem, selectedQuantity,priceFactor)
            data.cart = shoppingCartService.shoppingCart
            show("success", selectedQuantity + (selectedQuantity > 1 ? " items" : " item") + " added to the card.")
            SetData({ ...data })
        }
    }

    return <IonCard>
        <IonCardContent style={{ display: 'flex', flexWrap: "wrap" }}>
            <IonButton fill='outline' onClick={onDecreament}><FontAwesomeIcon icon={faMinus} /></IonButton>
            <div style={{ width: 95 }}>

                <IonInput onIonChange={onChange} ref={input} value={minQuantity} type='number' />
            </div>
            <IonButton fill='outline' onClick={onIncreament}><FontAwesomeIcon icon={faPlus} /></IonButton>
            <IonButton onClick={onAddToCart}><IonText>কার্টে যোগ করুন</IonText></IonButton>
            <ProductWishlistButton id={item.item_id} />

            <IonAlert
                isOpen={isOpen}
                ref={confirmRef}
                header="শপিং কার্ট ক্লিয়ার করবেন ?"
                message="এই প্রোডাক্টটি অর্ডার করতে হলে আগের শপিং কার্ট ক্লিয়ার করতে হবে"
                buttons={[
                    {
                        text: 'না',
                        role: 'cancel',
                        handler: () => {
                            setIsOpen(false)
                        },
                    },
                    {
                        text: 'হ্যাঁ',
                        role: 'confirm',
                        handler: () => {
                            shoppingCartService.clearCart()
                            data.cart = shoppingCartService.shoppingCart
                            SetData({ ...data })
                            setIsOpen(false)
                            onAddToCart()
                        },
                    },
                ]}
                onDidDismiss={() => setIsOpen(false)}
            ></IonAlert>
        </IonCardContent>
    </IonCard>
};

export default QuickViewAddToCart;
