import { IonCol, IonContent, IonRow, IonText, IonTitle, useIonViewDidEnter } from '@ionic/react';
import Header from '../components/Header/Header';
import { onScroll } from '../services/sticky-menu.service';
import Footer from '../components/Footer';

const ProhibitedItems: React.FC = () => {
    useIonViewDidEnter(() => {
        const tabBar = document.querySelector('ion-tab-bar');
        if (tabBar !== null) {
            tabBar.style.display = 'none';
        }
    })
    return (
        <IonContent fullscreen scrollEvents={true} onIonScroll={onScroll}>
            <Header />
            <IonRow>
                <IonCol sizeXl='10' offsetXl='1' sizeLg='10' offsetLg='1' className='no-padding bg-content'>
                    <div style={{ padding: 20, minHeight: '80vh' }}>

                        <IonTitle><h1 className='text-center'>Prohibited Items</h1></IonTitle>
                        <IonText><hr />
                            <p>বাংলাদেশ কাষ্টমস এর রুলস অনুযায়ী কিছু পন্যের ক্ষেত্রে সরকারের নিষেধাজ্ঞা রয়েছে যেগুলো আনা সম্ভব নয়। যেহেতু সাইটে এগুলো আলাদা করা সম্ভব নয় তাই এগুলো অর্ডার করা যাবেনা। যদি কেউ সরাসরি অর্ডার করেন তাহলে অর্ডার ক্যান্সেল হবে এবং আপনার পেমেন্টকৃত টাকা থেকে গেটওয়ে চার্জ ২.৫% কেটে বাকি টাকা রিফান্ড করা হবে। আমদানী নিষিদ্ধ পন্য নিচে দেয়া হলো</p>
                            <ul>
                                <li>মোবাইল ফোন</li>
                                <li>ডায়পার আনা যাবে শিপিং চার্জ আলোচনা সাপেক্ষে</li>
                                <li>ক্যামেরা (আলোচনা সাপেক্ষে আনা যাবে)</li>
                                <li>SKD (আমদানী নিষিদ্ধ পন্যের অংশ বিশেষ)</li>
                                <li>ওয়াকিটকি</li>
                                <li>নেটওয়ার্কিং পন্য (রাউটার/জিপন) শিপিং চার্জ প্রতি কেজি ১২০০ টাকা।</li>
                                <li>লিকুইড পন্য ১০০ গ্রামের উপরে</li>
                                <li>ব্যাটারী জাতীয় পন্য</li>
                                <li>মোবাইল টাচ স্ক্রিন</li>
                                <li>মোবাইল এর খালি বক্স</li>
                                <li>মোবাইল ব্যাটারী (আনা যাবে আলোচনা সাপেক্ষে)</li>
                                <li>মোবাইল LCD</li>
                                <li>টিভি প্যানেল</li>
                                <li>ল্যাপটপ (আনা যাবে আলোচনা সাপেক্ষে)</li>
                                <li>মেমোরী কার্ড (আনা যাবে আলোচনা সাপেক্ষে)</li>
                                <li>কেমিক্যাল আইটেম</li>
                                <li>গোল্ড</li>
                                <li>সিগারেট</li>
                                <li>ড্রোন/ ড্রোন এর পার্টস</li>
                                <li>সেক্স টয়</li>
                                <li>সিরিঞ্জ</li>
                                <li>গ্যাস লাইটার</li>
                                <li>পুরাতন পন্য</li>
                                <li>পাকিস্তানি ক্রিম</li>
                                <li>সিকিউরিটি পন্য হাতকড়া/পিস্তল/বন্দুক</li>
                            </ul>

                        </IonText>
                    </div>
                </IonCol>
            </IonRow>
            <Footer />
        </IonContent>
    );
};

export default ProhibitedItems;
