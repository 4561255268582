import { IonBadge, IonButton, IonCol, IonContent, IonRow } from '@ionic/react';
import Header from '../../components/Header/Header';
import { onScroll } from '../../services/sticky-menu.service';
import Footer from '../../components/Footer';
import { useEffect, useState } from 'react';
import { appSubDir } from '../../configs/app';
import Pagination from '../../components/Pagination';
import { fetchAllInvoices, getAllInvoices, getBadgeColor } from '../../services/account/invoices.service';
import { useHistory } from 'react-router';
import { useAppContext } from '../../services/app-context.service';
import { InvoiceFilterFormData } from '../../types/shop/invoice.type';
import InvoiceFilter from '../../components/Orders/InvoiceFilter';

const BDT = new Intl.NumberFormat('en-IN');
const Invoices: React.FC = () => {
    const history = useHistory()
    const { user } = useAppContext()
    const [loading, setLoadling] = useState(false)
    const response = getAllInvoices({ page: 1 })
    const [filter, setFilter] = useState({ page: 1 } as InvoiceFilterFormData)
    const [lastPage, setLastPage] = useState(0)
    const [items, setItems] = useState(response ? response.data : null)
    useEffect(() => {
        loadInvoices(filter)
    }, [])
    function loadInvoices(filter: InvoiceFilterFormData) {
        const local = getAllInvoices(filter)
        if (local) {
            setItems(local.data)
        } else {
            setLoadling(true)
        }
        fetchAllInvoices(filter).then((res) => {
            if (res) {
                setItems(res.data)
                setLastPage(res.last_page)
                setFilter({ ...filter, page: res.current_page })
            }
        }).finally(() => setLoadling(false))
    }
    function showDetails(id: string) {
        history.push(appSubDir + "invoices/" + id)
    }
    return (
        <IonContent fullscreen scrollEvents={true} onIonScroll={onScroll}>
            <Header />
            <IonRow>
                <IonCol sizeXl='10' offsetXl='1' sizeLg='10' offsetLg='1' className='no-padding bg-content'>
                    <div className='page-container'>
                        {!items && !loading && <div className='text-center'>
                            <h1 style={{ marginTop: 100, marginBottom: 100 }}>No Order Found</h1>
                            <IonButton routerLink={appSubDir} fill='outline'>
                                Go To Home
                            </IonButton>
                        </div>}
                        {items && <div className='page-container'>
                            <div className='title-bar' style={{ marginTop: 60, marginBottom: 50, display: "flex", justifyContent:"center",alignItems:"center" }}>
                                <h1 style={{marginRight:20}}>{user.roles.includes('admin') ? "All Orders" : "My Invoices"}</h1>
                                <InvoiceFilter filter={filter} onChange={loadInvoices} />
                            </div>
                            <div style={{ marginTop: 30 }}>
                                <table style={{ width: '100%' }}>
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th className='mobile-invisible'>Date</th>
                                            <th>Total</th>
                                            <th>Paid</th>
                                            <th>Due</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {items.map((item, index) => {
                                            if (item.status)
                                                return <tr style={{ cursor: "pointer" }} key={index} onClick={() => showDetails(item.invoice_id)}>
                                                    <td className='text-center'>#{item.invoice_id}</td>
                                                    <td className='text-center mobile-invisible'>{new Date(Date.parse(item.created_at)).toDateString()}</td>
                                                    <td className='text-end'>{BDT.format(item.total)}/=</td>
                                                    <td className='text-end'>{BDT.format(item.paid)}/=</td>
                                                    <td className='text-end'>{item.due ? BDT.format(item.due) : 0}/=</td>
                                                    <td className='text-center'><IonBadge color={getBadgeColor(item.status)}>{item.status}</IonBadge></td>
                                                </tr>
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <div style={{ marginTop: 30 }}>
                                {lastPage > 1 && <Pagination active={filter.page} disabled={loading} onClick={(page:number) => loadInvoices({ ...filter, page })} buttonCount={lastPage} />}
                            </div>
                        </div>}
                    </div>
                </IonCol>
            </IonRow>
            <Footer />
        </IonContent>
    );
};

export default Invoices;
